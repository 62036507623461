//import HttpClient from './HttpClient'
import HttpClientImpl from "./HttpClientImpl";
import { AxiosResponse } from "axios";
import {
  ApiServiceFn,
  ApiServiceRequestPayload,
  ApiServiceHttpResponse,
  HeaderType,
} from "./ApiServiceTypes";
import { getToken } from "./UserService";

let httpClient: HttpClientImpl<AxiosResponse<ApiServiceHttpResponse>> | null =
  null;

const getHttpClient = (): HttpClientImpl<
  AxiosResponse<ApiServiceHttpResponse>
> => {
  if (!httpClient) {
    httpClient = new HttpClientImpl<AxiosResponse<ApiServiceHttpResponse>>();
  }
  return httpClient;
};

export const postData: ApiServiceFn = async (
  payload: ApiServiceRequestPayload | null,
  endpoint: string,
  headersObj?: HeaderType,
): Promise<AxiosResponse<ApiServiceHttpResponse>> => {
  const token = await getToken();
  const tokenHeader = token
    ? {
        Authorization: "Bearer " + token,
      }
    : {};

  const headers = {
    ...headersObj,
    ...tokenHeader,
    "Content-Type": "application/json",
  };

  const request = {
    endpoint,
    headers,
    body: {
      ...payload,
    },
  };
  return getHttpClient().post(request);
};

export const getData: ApiServiceFn = async (
  payload: ApiServiceRequestPayload | null,
  endpoint: string,
  headersObj: HeaderType | undefined,
): Promise<AxiosResponse<ApiServiceHttpResponse>> => {
  const token = await getToken();
  const tokenHeader = token
    ? {
        Authorization: "Bearer " + token,
      }
    : {};

  const headers = {
    ...headersObj,
    ...tokenHeader,
  };

  const request = {
    endpoint,
    headers,
  };
  return getHttpClient().get(request);
};

export const putData: ApiServiceFn = async (
  payload: ApiServiceRequestPayload | null,
  endpoint: string,
  headersObj?: HeaderType,
): Promise<AxiosResponse<ApiServiceHttpResponse>> => {
  const token = await getToken();
  const tokenHeader = token
    ? {
        Authorization: "Bearer " + token,
      }
    : {};

  const headers = {
    ...headersObj,
    ...tokenHeader,
  };

  const request = {
    endpoint,
    headers,
    body: {
      ...payload,
    },
  };
  return getHttpClient().put(request);
};

export const deleteData: ApiServiceFn = async (
  payload: ApiServiceRequestPayload | null,
  endpoint: string,
  headersObj: HeaderType | undefined,
): Promise<AxiosResponse<ApiServiceHttpResponse>> => {
  const token = await getToken();
  const tokenHeader = token
    ? {
        Authorization: "Bearer " + token,
      }
    : {};

  const headers = {
    ...headersObj,
    ...tokenHeader,
  };

  const request = {
    endpoint,
    headers,
  };
  return getHttpClient().delete(request);
};
