import {
  takeEvery,
  put,
  select,
  all,
  delay,
  call,
  take,
  cancel,
  fork,
} from "typed-redux-saga/macro";

import {
  LOAD_PRODUCT_DETAILS,
  LoadProductDetailsAction,
  FETCH_PRODUCT_DETAILS,
  CHANGE_PRODUCT_PUBLISH_STATE,
  CHANGE_PRODUCT_DELETE_STATE,
  UpdateProductPublishStateAction,
  UpdateProductDeleteStateAction,
  UPDATE_PRODUCT_PUBLISH_STATE,
  UPDATE_PRODUCT_DELETE_STATE,
  UpdateProductPublishStateResponse,
  UpdateProductDeleteStateResponse,
  UPDATE_PRODUCT_TO_PRODUCT_SEQUENCE,
  FETCH_PRODUCT_ANALYTICS,
  LoadProductAnalyticsAction,
  LOAD_PRODUCT_ANALYTICS,
  LOAD_CATEGORY_BY_PRODUCT_ID,
  LoadCategoryByProductIdAction,
  FETCH_CATEGORY_BY_PRODUCT_ID,
  CHANGE_PRODUCT_ATTRIBUTE,
  UpdateProductAttributeAction,
  UPDATE_PRODUCT_ATTRIBUTE,
  UpdateProductAttributeResponse,
  UpdateLocaleProductPublishAction,
  UPDATE_LOCALE_PRODUCT_PUBLISH,
  UpdateLocaleProductPublishResponse,
  CHANGE_LOCALE_PRODUCT_PUBLISH,
  CheckValidationProductCodesAction,
  CHECK_VALIDATION_PRODUCT_CODES,
  GET_VALIDATION_PRODUCT_CODES,
  CheckValidationProductCodesResponse,
  UPDATE_ATTR_VALUE_SEQUENCE,
  CHANGE_PRODUCT_COLORS,
  UpdateProductColorsAction,
  UPDATE_LEADING_COLOR_BY_CATEGORY,
  ProductSequence,
  AttrValueSequence,
  UPDATE_ATTR_VALUE_DESCRIPTION_SEQUENCE,
  UpdateProductColorsResponse,
  UpdateProductPublishByDefaultStateAction,
  UpdateProductPublishByDefaultStateResponse,
  CHANGE_PRODUCT_PUBLISH_BY_DEFAULT_STATE,
  UPDATE_PRODUCT_PUBLISH_BY_DEFAULT_STATE,
  UpdateProductPublishStateByStoreAction,
  UPDATE_PRODUCT_PUBLISH_STATE_BY_STORE,
  UpdateProductPublishStateByStoreResponse,
  CHANGE_PRODUCT_PUBLISH_STATE_BY_STORE,
  UpdateSingleProductDeleteStateAction,
  UPDATE_SINGLE_PRODUCT_DELETE_STATE,
  CHANGE_SINGLE_PRODUCT_DELETE_STATE,
  ToChildProduct,
  FETCH_PRODUCT_DETAILS_FOR_REPORT,
  LOAD_PRODUCT_DETAILS_FOR_REPORT,
  LOAD_ANALYTICS_SERVICE_URL,
  FETCH_PRODUCTS_VARIANTS,
  GET_PRODUCTS_VARIANTS,
  FetchProductsVariantsAction,
  PUT_PRODUCT_VARIANT_PUBLISH_STATE,
  UpdateProductVariantPublishStateAction,
  UPDATE_PRODUCT_VARIANT_PUBLISH_STATE,
  RESET_PRODUCTS_VARIANTS_STATE,
  RESET_PRODUCT_VARIANTS,
  ADD_MIGRATED_CATEGORIES,
  ADD_MIGRATED_CATEGORIES_DATA,
  ADD_MIGRATED_CATEGORIES_NEW,
  ADD_MIGRATED_CATEGORIES_NEW_DATA,
  UpdateProductPublishFlagAction,
  UPDATE_PRODUCT_ISPUBLISHED_STATE,
  UPDATE_PRODUCT_ISPUBLISHED,
  RESET_UPDATE_PRODUCT_ISPUBLISHED_STATE,
  RESET_UPDATE_PRODUCT_ISPUBLISHED,
  ADD_PRODUCT_IN_CATEGORY,
  updateProductAddCategoryResponseAction,
  REMOVE_PRODUCT_IN_CATEGORY,
  UPDATE_PUBLISH_FLAG,
  LOAD_SINGLE_PRODUCT_ANALYTICS_STATE,
  FETCH_SINGLE_PRODUCT_ANALYTICS,
  loadSingleProductAnalyticsAction,
} from "./ProductTypes";
import {
  updateProductPublishStateQuery,
  updateProductDeleteStateQuery,
  updateProductToProductSequenceQeuryGenerator,
  insertProductLeadingColorQuery,
  deleteProductLeadingColorQuery,
  updateProductLeadingColorQuery,
  insertProductAttributeQuery,
  deleteProductAttributeQuery,
  updateProductAttributeQuery,
  updateLocaleProductPublishQuery,
  checkValidationCodesQuery,
  updateAttrValueSequenceQueryGenerator,
  updateAttrValueDescriptionSequenceQueryGenerator,
  updateProductPublishedByDefaultStateQuery,
  updateProductPublishedStateByStoreQuery,
  updateSingleProductDeleteStateQuery,
  insertProductAttributeOverrideQuery,
  updateProductAttributeOverrideQuery,
  deleteProductAttributeOverrideQuery,
  insertProductLocalizedAttributeQuery,
  updateProductLocalizedAttributeQuery,
  deleteProductLocalizedAttributeQuery,
} from "./ProductSchema";
import { callGraphQLApi, callApi } from "../../utils/SagaUtils";
import GraphQLService from "../../services/GraphQL";
import {
  LEADING_COLOR_CHANGE_TYPE,
  PRODUCT_ATTRIBUTE_CHANGE_TYPE,
  PAGINATION_CHUNK_SIZE,
} from "../../utils/Constants";
import {
  postData,
  getData,
  putData,
  deleteData,
} from "../../services/ApiService";
import { setModalState } from "../modal/ModalActions";
import { addGlobalAlertState } from "../global-alert/GlobalAlertActions";
import { MultipleStoreLanguageModalId } from "../../components/store-language-modal/MultipleStoreLanguageModal";
import { AlertProps } from "../global-alert/GlobalAlertTypes";
import { itemManagementModalId } from "../../components/item-management/ItemManagementModal";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../store-list/StoreListSelectors";
import { SyncLogInserts, GraphQLQuery } from "../../services/GraphQLTypes";
import { ColorManagementModalId } from "../../components/color-mangement-modal/ColorManagement";
import { MultipleStoreModalId } from "../../components/multiple-store-modal/MultipleStoreModal";
import { ItemManagementSFCCTableId } from "../../components/item-management-SFCC/ItemManagementSFCCTable";
import AppState from "../AppState";
import { selectChildrenItems } from "./ProductSelectors";
import {
  resetSingleProducAnalyticView,
  updateChildrenItems,
} from "./ProductActions";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { selectCurrentCatalogId } from "../catalog/CatalogSelectors";
import {
  CATEGORY_CHANGE,
  FETCH_PRODUCT,
} from "../product-list/ProductListTypes";
import { VERIFY_CLIPBOARD_PRODUCT_CODES } from "../clipboard/ClipBoardTypes";
import { chunk } from "../../utils/ArrayUtils";
import {
  LoadTopCategoriesByCatalogId,
  LoadChildCategories,
} from "../category/CategoryActions";
import {
  productLoadingCancelled,
  refreshProductFromList,
} from "../product-list/ProductListActions";
import { MODAL_CLOSED } from "store/modal/ModalTypes";

function* getProductDetails(action: LoadProductDetailsAction) {
  try {
    const actionType = FETCH_PRODUCT_DETAILS;
    const catalogId = action.payload.catalogId;
    const categoryId = action.payload.categoryId;
    const storeId = action.payload.storeId;
    const localeCode = yield* select(selectCurrentLocale);

    const constName = Object.keys({ FETCH_PRODUCT_DETAILS })[0].toString();

    const endpoint = acquireEndpoint(constName, categoryId);

    const headersObj = {
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
      "x-locale-code": localeCode || "default",
    };

    yield* call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield* put({ type: FETCH_PRODUCT_DETAILS.FAILURE, message: e.message });
  }
}

function* getProductDetailsForReport(action: LoadProductDetailsAction) {
  try {
    const actionType = FETCH_PRODUCT_DETAILS_FOR_REPORT;
    const productId = action.payload.productId;
    const constName = Object.keys({
      FETCH_PRODUCT_DETAILS_FOR_REPORT,
    })[0].toString();

    const endpoint = acquireEndpoint(constName, productId);

    yield* call(callApi, actionType, getData, action.payload, endpoint);
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: FETCH_PRODUCT_DETAILS_FOR_REPORT.FAILURE,
      message: e.message,
    });
  }
}

function* updateProductPublishState(action: UpdateProductPublishStateAction) {
  const actionType = UPDATE_PRODUCT_PUBLISH_STATE;

  try {
    const publishedProductIds = action.payload.publishedProductIds;
    const unpublishedProductIds = action.payload.unpublishedProductIds;
    const publishedProductIdsLength = publishedProductIds.length;
    const unpublishedProductIdsLength = unpublishedProductIds.length;
    const productIds = [...publishedProductIds, ...unpublishedProductIds];
    const syncLogInserts = productIds.map((productId) => {
      return {
        tableName: "Product",
        operationType: "UPDATED",
        keyName1: "productId",
        oldKeyValue1: productId,
        newKeyValue1: productId,
      };
    });

    const query = {
      query: updateProductPublishStateQuery,
      variables: {
        publishedProductIds,
        unpublishedProductIds,
        syncLogInserts: syncLogInserts,
      },
    };

    const result: UpdateProductPublishStateResponse = yield callGraphQLApi(
      actionType,
      GraphQLService.query,
      query,
    );
    /**
     * TODO : Add error handling
     */

    if (
      result?.type === actionType.SUCCESS &&
      (result.payload.data?.publish.affected_rows ||
        result.payload.data?.unpublish.affected_rows)
    ) {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productOperations.updateProductPublishStateSuccess",
                defaultMessage:
                  "Published {published} and Unpublished {unpublished} products for all selected stores",
              },
              variables: {
                published: `${publishedProductIdsLength}`,
                unpublished: `${unpublishedProductIdsLength}`,
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield* put({ type: actionType.FAILURE, message: e.message });
  }
}

function* updateProductPublishedByDefaultState(
  action: UpdateProductPublishByDefaultStateAction,
) {
  const actionType = UPDATE_PRODUCT_PUBLISH_BY_DEFAULT_STATE;
  const { childId, parentId, isPublishedByDefault } = action.payload;
  try {
    const syncLogInserts = [
      {
        tableName: "Product",
        operationType: "UPDATED",
        keyName1: "productId",
        oldKeyValue1: childId,
        newKeyValue1: childId,
      },
    ];

    const query = {
      query: updateProductPublishedByDefaultStateQuery,
      variables: {
        productId: childId,
        isPublishedByDefault,
        syncLogInserts: syncLogInserts,
      },
    };

    yield* put(
      setModalState(ItemManagementSFCCTableId, true, {
        loadingCheckId: childId,
      }),
    );

    const result: UpdateProductPublishByDefaultStateResponse =
      yield callGraphQLApi(actionType, GraphQLService.query, query);

    yield* delay(500);

    if (
      result?.type === actionType.SUCCESS &&
      result.payload.data?.update_Product.affected_rows
    ) {
      let selectedChildrenItems = yield* select(
        (state: AppState) => selectChildrenItems(state, parentId) ?? [],
      );
      const clone = selectedChildrenItems.map((item) => {
        const newItem = {
          ...item,
          childProduct: {
            ...item.childProduct,
          },
        };
        return newItem;
      });
      const index = yield clone.findIndex(
        (child) => child.childProductId === childId,
      );
      clone[index].childProduct["isPublished"] = isPublishedByDefault;
      yield* put(
        updateChildrenItems({
          parentId,
          toChildProducts: clone,
        }),
      );
      yield* put(
        setModalState(ItemManagementSFCCTableId, true, {
          successCheckId: childId,
        }),
      );

      yield* delay(500);
      yield* put(
        setModalState(ItemManagementSFCCTableId, true, {
          successCheckId: null,
        }),
      );
    } else if (result?.type === actionType.FAILURE) {
      yield* put(
        setModalState(ItemManagementSFCCTableId, true, {
          failureCheckId: childId,
        }),
      );
      yield* delay(500);
      yield* put(
        setModalState(ItemManagementSFCCTableId, true, {
          failureCheckId: null,
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield* put({ type: actionType.FAILURE, message: e.message });
  } finally {
    yield* put(
      setModalState(ItemManagementSFCCTableId, true, {
        loadingCheckId: null,
      }),
    );
  }
}

function* updateProductPublishedStateByStore(
  action: UpdateProductPublishStateByStoreAction,
) {
  const actionType = UPDATE_PRODUCT_PUBLISH_STATE_BY_STORE;
  try {
    const { parentId, childId, isPublishedByStore, storeId } = action.payload;
    const syncLogInserts = [
      {
        tableName: "ProductOverride",
        operationType: "INSERTED",
        keyName1: "productId",
        oldKeyValue1: childId,
        newKeyValue1: childId,
        keyName2: "storeId",
        oldKeyValue2: storeId,
        newKeyValue2: storeId,
      },
    ];

    const query = {
      query: updateProductPublishedStateByStoreQuery,
      variables: {
        productId: childId,
        storeId,
        isPublishedByStore,
        syncLogInserts: syncLogInserts,
      },
    };
    yield* put(
      setModalState(ItemManagementSFCCTableId, true, {
        loadingSelectId: childId,
        successSelectId: null,
      }),
    );
    yield* delay(500);

    const result: UpdateProductPublishStateByStoreResponse =
      yield callGraphQLApi(actionType, GraphQLService.query, query);

    if (
      result?.type === actionType.SUCCESS &&
      result.payload.data?.insert_ProductOverride.affected_rows
    ) {
      yield* put(
        setModalState(ItemManagementSFCCTableId, true, {
          successSelectId: childId,
        }),
      );

      yield* delay(500);
      const selectedChildrenItems = yield* select(
        (state: AppState) => selectChildrenItems(state, parentId) ?? [],
      );
      const clone = selectedChildrenItems.map((item) => {
        const newItem = {
          ...item,
          childProduct: {
            ...item.childProduct,
            overrides: [...item.childProduct.overrides],
          },
        };
        return newItem;
      });
      const index = yield clone.findIndex(
        (child) => child.childProductId === childId,
      );
      const itemIndex = clone[index].childProduct["overrides"].findIndex(
        (item) => item.storeId === storeId,
      );

      if (itemIndex !== -1) {
        clone[index].childProduct["overrides"][itemIndex].isPublished =
          isPublishedByStore;
      } else {
        clone[index].childProduct["overrides"].push({
          isPublished: isPublishedByStore,
          storeId,
        });
      }

      yield* put(
        updateChildrenItems({
          parentId,
          toChildProducts: clone,
        }),
      );
      yield* put(
        setModalState(ItemManagementSFCCTableId, true, {
          successSelectId: null,
        }),
      );
    } else if (result?.type === actionType.FAILURE) {
      yield* put(
        setModalState(ItemManagementSFCCTableId, true, {
          failureSelectId: childId,
        }),
      );
      yield* delay(500);
      yield* put(
        setModalState(ItemManagementSFCCTableId, true, {
          failureSelectId: null,
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield* put({ type: actionType.FAILURE, message: e.message });
  } finally {
    yield* put(
      setModalState(ItemManagementSFCCTableId, true, {
        loadingSelectId: null,
      }),
    );
  }
}

function* updateProductDeleteState(action: UpdateProductDeleteStateAction) {
  let actionType = UPDATE_PRODUCT_DELETE_STATE;

  try {
    const productIds = action.payload.productIds;
    const isDeleted = action.payload.isDeleted;
    const syncLogInserts = productIds.map((productId) => {
      return {
        tableName: "Product",
        operationType: "DELETED",
        keyName1: "productId",
        oldKeyValue1: productId,
        newKeyValue1: productId,
      };
    });

    const query = {
      query: updateProductDeleteStateQuery,
      variables: {
        productIds: productIds,
        isDeleted: isDeleted,
        syncLogInserts: syncLogInserts,
      },
    };

    const result: UpdateProductDeleteStateResponse = yield callGraphQLApi(
      actionType,
      GraphQLService.query,
      query,
    );
    /**
     * TODO : Add success and error handling
     */
    if (result) {
      if (result.type === actionType.SUCCESS) {
        if (
          result.payload.data &&
          result.payload.data.update_Product.affected_rows
        ) {
        }
      }
    }
  } catch (e: any) {
    console.error(e);
    yield* put({ type: actionType.FAILURE, message: e.message });
  }
}

function* updateSingleProductDeleteState(
  action: UpdateSingleProductDeleteStateAction,
) {
  const actionType = UPDATE_SINGLE_PRODUCT_DELETE_STATE;

  const { parentId, childId, isDeleted } = action.payload;
  try {
    const syncLogInserts = [
      {
        tableName: "Product",
        operationType: "DELETED",
        keyName1: "productId",
        oldKeyValue1: childId,
        newKeyValue1: childId,
      },
    ];

    const query = {
      query: updateSingleProductDeleteStateQuery,
      variables: {
        productId: childId,
        isDeleted: isDeleted,
        syncLogInserts: syncLogInserts,
      },
    };

    yield* put(
      setModalState(ItemManagementSFCCTableId, true, {
        loadingDeleteId: childId,
        successDeleteId: null,
      }),
    );

    yield* delay(500);

    const result: UpdateProductDeleteStateResponse = yield callGraphQLApi(
      actionType,
      GraphQLService.query,
      query,
    );

    if (
      result?.type === actionType.SUCCESS &&
      result.payload.data?.update_Product.affected_rows
    ) {
      yield* put(
        setModalState(ItemManagementSFCCTableId, true, {
          successDeleteId: childId,
        }),
      );
      yield* delay(500);

      const selectedChildrenItems: ToChildProduct[] = yield* select(
        (state: AppState) => selectChildrenItems(state, parentId) ?? [],
      );
      const clone = selectedChildrenItems.map((item) => {
        const newItem = {
          ...item,
          childProduct: {
            ...item.childProduct,
          },
        };
        return newItem;
      });
      const index = yield clone.findIndex(
        (child) => child.childProductId === childId,
      );
      clone[index].childProduct["isDeleted"] = isDeleted;

      yield* put(
        updateChildrenItems({
          parentId,
          toChildProducts: clone,
        }),
      );

      yield* put(
        setModalState(ItemManagementSFCCTableId, true, {
          successDeleteId: null,
        }),
      );
    } else if (result?.type === actionType.FAILURE) {
      yield* put(
        setModalState(ItemManagementSFCCTableId, true, {
          failureDeleteId: childId,
        }),
      );
      yield* delay(500);
      yield* put(
        setModalState(ItemManagementSFCCTableId, true, {
          failureDeleteId: null,
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield* put({ type: actionType.FAILURE, message: e.message });
  } finally {
    yield* put(
      setModalState(ItemManagementSFCCTableId, true, {
        loadingDeleteId: null,
      }),
    );
  }
}

function getLocalePublishQuery(
  localeCodes: string[],
  productIds: string[],
  publishedProductIds: string[],
  unpublishedProductIds: string[],
) {
  const syncLogInserts: SyncLogInserts[] = [];
  const updateQuery: any = {
    query: updateProductPublishStateQuery,
    variables: {
      publishedProductIds,
      unpublishedProductIds,
      localeCodes,
      syncLogInserts,
    },
  };
  if (localeCodes.length > 0) {
    updateQuery.query = updateLocaleProductPublishQuery;
    productIds.forEach((productId) => {
      const temp = localeCodes.map((code) => ({
        tableName: "ProductDescription",
        operationType: "UPDATED",
        keyName1: "localeCode",
        oldKeyValue1: code,
        newKeyValue1: code,
        keyName2: "productId",
        oldKeyValue2: productId,
        newKeyValue2: productId,
      }));
      syncLogInserts.push(...temp);
    });
  } else {
    delete updateQuery.variables.localeCodes;
    productIds.forEach((productId) => {
      syncLogInserts.push({
        tableName: "Product",
        operationType: "UPDATED",
        keyName1: "productId",
        oldKeyValue1: productId,
        newKeyValue1: productId,
      });
    });
  }

  return updateQuery;
}

function getProductToProductGraphQLQuery(
  productSequences: ProductSequence[],
  parentProductId: string,
) {
  const productToProductQuery =
    updateProductToProductSequenceQeuryGenerator(productSequences);
  const syncLogInserts = productSequences.map((productSequence) => {
    return {
      tableName: "ProductToProduct",
      operationType: "UPDATED",
      keyName1: "parentProductId",
      oldKeyValue1: parentProductId,
      newKeyValue1: parentProductId,
      keyName2: "childProductId",
      oldKeyValue2: productSequence.productId,
      newKeyValue2: productSequence.productId,
      keyName3: "typeCode",
      oldKeyValue3: productSequence.type,
      newKeyValue3: productSequence.type,
    };
  });

  return {
    query: productToProductQuery,
    variables: {
      parentProductId,
      syncLogInserts,
    },
  };
}

function getLeadingColorGrpahQLQuery(
  storeId: string,
  catalogId: string,
  categoryId: string,
  productId: string,
  type: number,
  attributeValueId: string,
) {
  const syncLogInserts: any = {
    tableName: "ProductLeadingColor",
    operationType: "UPDATED",
    keyName1: "storeId",
    oldKeyValue1: storeId,
    newKeyValue1: storeId,
    keyName2: "catalogId",
    oldKeyValue2: catalogId,
    newKeyValue2: catalogId,
    keyName3: "categoryId",
    oldKeyValue3: categoryId,
    newKeyValue3: categoryId,
    keyName4: "productId",
    oldKeyValue4: productId,
    newKeyValue4: productId,
  };
  let leadingColorQuery;
  const insertMode = type === LEADING_COLOR_CHANGE_TYPE.INSERT;
  const updateMode = type === LEADING_COLOR_CHANGE_TYPE.UPDATE;
  if (insertMode) {
    leadingColorQuery = insertProductLeadingColorQuery;
    syncLogInserts.operationType = "INSERTED";
    delete syncLogInserts.oldKeyValue1;
    delete syncLogInserts.oldKeyValue2;
    delete syncLogInserts.oldKeyValue3;
    delete syncLogInserts.oldKeyValue4;
  } else if (updateMode) leadingColorQuery = updateProductLeadingColorQuery;
  else {
    leadingColorQuery = deleteProductLeadingColorQuery;
    syncLogInserts.operationType = "DELETED";
    delete syncLogInserts.newKeyValue1;
    delete syncLogInserts.newKeyValue2;
    delete syncLogInserts.newKeyValue3;
    delete syncLogInserts.newKeyValue4;
  }

  const query = {
    query: leadingColorQuery,
    variables: {
      storeId,
      catalogId,
      categoryId,
      productId,
      attributeValueId,
      syncLogInserts: [syncLogInserts],
    },
  };

  return query;
}

function* updateProductColors(action: UpdateProductColorsAction) {
  try {
    const localeCodes = action.payload.localeCodes;
    const publishedProductIds = action.payload.publishedProductIds;
    const unpublishedProductIds = action.payload.unpublishedProductIds;
    const productSequences = action.payload.productSequences;
    const productId = action.payload.productId;
    const attrValSequences = action.payload.attrValueSequences;
    const storeId = action.payload.storeId;
    const catalogId = action.payload.catalogId;
    const categoryId = action.payload.categoryId;
    const type = action.payload.type;
    const attributeValueId = action.payload.leadingColor;
    const publishProductsLength = publishedProductIds.length;
    const unpublishProductsLength = unpublishedProductIds.length;

    const generatorCalls: Generator[] = [];
    if (publishProductsLength || unpublishProductsLength) {
      const publishQuery = getLocalePublishQuery(
        localeCodes,
        [...publishedProductIds, ...unpublishedProductIds],
        publishedProductIds,
        unpublishedProductIds,
      );
      const productPublish = callGraphQLApi(
        localeCodes.length > 0
          ? UPDATE_LOCALE_PRODUCT_PUBLISH
          : UPDATE_PRODUCT_PUBLISH_STATE,
        GraphQLService.query,
        publishQuery,
      );
      generatorCalls.push(productPublish);
    }
    if (productSequences && productSequences.length > 0) {
      const productSequenceQuery = getProductToProductGraphQLQuery(
        productSequences,
        productId,
      );

      const productSequenceCall = callGraphQLApi(
        UPDATE_PRODUCT_TO_PRODUCT_SEQUENCE,
        GraphQLService.query,
        productSequenceQuery,
      );
      generatorCalls.push(productSequenceCall);
    } else if (attrValSequences && attrValSequences.length > 0) {
      const attrValQuery = getAttributeValueSquenceGraphQLQuery(
        attrValSequences,
        localeCodes,
      );
      generatorCalls.push(
        callGraphQLApi(
          localeCodes.length > 0
            ? UPDATE_ATTR_VALUE_DESCRIPTION_SEQUENCE
            : UPDATE_ATTR_VALUE_SEQUENCE,
          GraphQLService.query,
          attrValQuery,
        ),
      );
    }

    if (type !== LEADING_COLOR_CHANGE_TYPE.NOUPDATE) {
      const leadingColorQuery = getLeadingColorGrpahQLQuery(
        storeId,
        catalogId,
        categoryId,
        productId,
        type,
        attributeValueId,
      );

      generatorCalls.push(
        callGraphQLApi(
          UPDATE_LEADING_COLOR_BY_CATEGORY,
          GraphQLService.query,
          leadingColorQuery,
        ),
      );
    }
    const results: UpdateProductColorsResponse[] = yield* all(generatorCalls);

    if (results && results.length === generatorCalls.length) {
      const alertsProps: AlertProps[] = [];
      results.forEach((result) => {
        const resultType = (result as UpdateProductColorsResponse).type;
        switch (resultType) {
          case UPDATE_LOCALE_PRODUCT_PUBLISH.SUCCESS:
          case UPDATE_PRODUCT_PUBLISH_STATE.SUCCESS:
            alertsProps.push({
              descriptor: {
                id: "productOperations.updateLocaleProductPublishSuccess",
                defaultMessage:
                  "Published {published} and Unpublished {unpublished} products for all selected stores/locales",
              },
              variables: {
                published: `${publishProductsLength}`,
                unpublished: `${unpublishProductsLength}`,
              },
              severity: "success",
              variant: "standard",
            });
            break;
          case UPDATE_PRODUCT_TO_PRODUCT_SEQUENCE.SUCCESS:
            alertsProps.push({
              descriptor: {
                id: "productOperations.updateProductSequenceSuccess",
                defaultMessage: "Updated {length} product sequences",
              },
              variables: {
                length: `${productSequences.length}`,
              },
              severity: "success",
              variant: "standard",
            });
            break;
          case UPDATE_ATTR_VALUE_DESCRIPTION_SEQUENCE.SUCCESS:
          case UPDATE_ATTR_VALUE_SEQUENCE.SUCCESS:
            alertsProps.push({
              descriptor: {
                id: "productOperations.updateColorSequenceSuccess",
                defaultMessage:
                  "Updated {length} color sequences in selected locales",
              },
              variables: {
                length: `${attrValSequences.length}`,
              },
              severity: "success",
              variant: "standard",
            });
            break;
          case UPDATE_LEADING_COLOR_BY_CATEGORY.SUCCESS:
            alertsProps.push({
              descriptor: {
                id: "productOperations.updateLeadingColorByCategorySuccess",
                defaultMessage: "{operationType} leading color for the product",
              },
              variables: {
                operationType: LEADING_COLOR_CHANGE_TYPE.INSERT
                  ? "Inserted"
                  : LEADING_COLOR_CHANGE_TYPE.UPDATE
                    ? "Updated"
                    : "Deleted",
              },
              severity: "success",
              variant: "standard",
            });
            break;
        }
      });
      yield* put(
        setModalState(MultipleStoreLanguageModalId, false, {
          loading: false,
        }),
      );
      yield* put(
        setModalState(ColorManagementModalId, false, { loading: false }),
      );
      yield* put(
        addGlobalAlertState({
          alertsProps,
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
  }
}

export function getAttributeValueSquenceGraphQLQuery(
  attrValSequences: AttrValueSequence[],
  localeCodes: string[],
) {
  const attrValueQuery = {
    query: updateAttrValueSequenceQueryGenerator(attrValSequences),
    variables: {},
  };
  if (localeCodes.length > 0) {
    attrValueQuery.query =
      updateAttrValueDescriptionSequenceQueryGenerator(attrValSequences);
    const syncLogInserts: SyncLogInserts[] = [];
    attrValSequences.forEach((attrValSequence) => {
      localeCodes.forEach((localeCode) => {
        syncLogInserts.push({
          tableName: "AttributeValueDescription",
          operationType: "UPDATED",
          keyName1: "attributeValueId",
          oldKeyValue1: attrValSequence.attributeValueId,
          newKeyValue1: attrValSequence.attributeValueId,
          keyName2: "localeCode",
          oldKeyValue2: localeCode,
          newKeyValue2: localeCode,
        });
      });
    });
    attrValueQuery.variables = {
      localeCodes,
      syncLogInserts,
    };
  } else {
    const syncLogInserts = attrValSequences.map((attrValSequence) => {
      return {
        tableName: "AttributeValue",
        operationType: "UPDATED",
        keyName1: "attributeValueId",
        oldKeyValue1: attrValSequence.attributeValueId,
        newKeyValue1: attrValSequence.attributeValueId,
      };
    });
    attrValueQuery.variables = {
      syncLogInserts,
    };
  }

  return attrValueQuery;
}

function* checkValidationProductCodes(
  action: CheckValidationProductCodesAction,
) {
  const actionType = CHECK_VALIDATION_PRODUCT_CODES;
  try {
    const productCodes = action.payload.ProductCodes;
    const query = {
      query: checkValidationCodesQuery,
      variables: {
        productCodes,
      },
    };

    const result: CheckValidationProductCodesResponse = yield callGraphQLApi(
      actionType,
      GraphQLService.query,
      query,
    );
    const validProductsCode = result.payload.data.Product.map(
      (item) => item.code,
    );
    const inValidProductsCode = productCodes.filter(
      (code) => !validProductsCode.includes(code),
    );
    return { invalidCodes: inValidProductsCode, validCodes: validProductsCode };
  } catch (error) {
    console.log(error);
  }
}

function* updateLocaleProductPublish(action: UpdateLocaleProductPublishAction) {
  const actionType = UPDATE_LOCALE_PRODUCT_PUBLISH;

  try {
    const publishedProductIds = action.payload.publishedProductIds;
    const unpublishedProductIds = action.payload.unpublishedProductIds;
    const localeCodes = action.payload.localeCodes;
    const validationCodesResult: {
      invalidCodes: string[];
      validCodes: string[];
    } = yield checkValidationProductCodes({
      payload: {
        ProductCodes: [...publishedProductIds, ...unpublishedProductIds],
      },
      type: "GET_VALIDATION_PRODUCT_CODES",
    });
    let syncLogInserts: SyncLogInserts[] = [];

    validationCodesResult.validCodes.forEach((productId) => {
      const temp = localeCodes.map((code) => ({
        tableName: "ProductDescription",
        operationType: "UPDATED",
        keyName1: "localeCode",
        oldKeyValue1: code,
        newKeyValue1: code,
        keyName2: "productId",
        oldKeyValue2: productId,
        newKeyValue2: productId,
      }));
      syncLogInserts.push(...temp);
    });

    const query = getLocalePublishQuery(
      localeCodes,
      validationCodesResult.validCodes,
      publishedProductIds,
      unpublishedProductIds,
    );
    const result: UpdateLocaleProductPublishResponse = yield callGraphQLApi(
      actionType,
      GraphQLService.query,
      query,
    );

    if (result.type === actionType.SUCCESS) {
      const publishCodes = result.payload.data.publish.returning.map(
        (item) => item.product.code,
      );
      const unpublishCodes = result.payload.data.unpublish.returning.map(
        (item) => item.product.code,
      );

      const uniquePublishCodes = [...new Set(publishCodes)];
      const uniqueUnpublishCodes = [...new Set(unpublishCodes)];

      const alertsProps: AlertProps[] = [];
      const uniquePublishCodesLength = uniquePublishCodes.length;
      const uniqueUnpublishCodesLength = uniqueUnpublishCodes.length;

      yield* put(
        setModalState(MultipleStoreLanguageModalId, false, {
          loading: false,
        }),
      );
      yield* put(
        setModalState(itemManagementModalId, false, { loading: false }),
      );

      if (uniquePublishCodesLength || uniqueUnpublishCodesLength) {
        alertsProps.push({
          descriptor: {
            id: "productOperations.updateLocaleProductPublishSuccess",
            defaultMessage:
              "Published {published} and Unpublished {unpublished} products for all selected stores/locales",
          },
          variables: {
            published: `${uniquePublishCodesLength}`,
            unpublished: `${uniqueUnpublishCodesLength}`,
          },
          severity: "success",
          variant: "standard",
        });
      }
      if (validationCodesResult.invalidCodes.length) {
        alertsProps.push({
          descriptor: {
            id: "productOperations.updateLocaleProductPublishInvalidCodesWarning",
            defaultMessage: "{validationCodesResult} are not valid code(s).",
          },
          variables: {
            validationCodesResult:
              validationCodesResult.invalidCodes.join(", "),
          },
          severity: "warning",
          variant: "standard",
        });
      }
      yield* put(
        addGlobalAlertState({
          alertsProps,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchProductAnalytics(action: LoadProductAnalyticsAction) {
  try {
    let actionType = FETCH_PRODUCT_ANALYTICS;
    const storeId = yield* select(selectCurrentStoreId);
    const catalogId = yield* select(selectCurrentCatalogId);
    const localeCode = yield* select(selectCurrentLocale);
    const constName = Object.keys({ FETCH_PRODUCT_ANALYTICS })[0].toString();
    const endpoint = acquireEndpoint(constName, LOAD_ANALYTICS_SERVICE_URL);

    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };

    yield* call(
      callApi,
      actionType,
      postData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield* put({ type: FETCH_PRODUCT_ANALYTICS.FAILURE, message: e.message });
  }
}

function* loadCategoryByProductId(action: LoadCategoryByProductIdAction) {
  const storeId = yield* select(selectCurrentStoreId);
  const catalogId = yield* select(selectCurrentCatalogId);
  const localeCode = yield* select(selectCurrentLocale);
  const actionType = FETCH_CATEGORY_BY_PRODUCT_ID;
  const constName = Object.keys({
    FETCH_CATEGORY_BY_PRODUCT_ID,
  })[0].toString();

  const productIdChunks = chunk(
    PAGINATION_CHUNK_SIZE,
    action.payload.productIds,
  );

  for (let i = 0; i < productIdChunks.length; i++) {
    let productIds = productIdChunks[i];

    try {
      const headersObj = {
        "x-locale-code": localeCode || "default",
        "x-currency-code": "USD",
        "x-store-id": storeId,
        "x-catalog-id": catalogId,
      };

      const endpoint = acquireEndpoint(constName);
      yield* call(
        callApi,
        actionType,
        postData,
        {
          ...action.payload,
          productIds,
        },
        endpoint,
        headersObj,
      );
    } catch (e: any) {
      console.error(e);
      yield* put({
        type: FETCH_CATEGORY_BY_PRODUCT_ID.FAILURE,
        message: e.message,
      });
    }
  }
}

const getAttributeUpdateQuery = (
  type: number,
  syncLogInserts: SyncLogInserts,
  insertQuery: string,
  updateQuery: string,
  deleteQuery: string,
): string => {
  let productAttributeQuery;
  if (type === PRODUCT_ATTRIBUTE_CHANGE_TYPE.INSERT) {
    productAttributeQuery = insertQuery;
    syncLogInserts.operationType = "INSERTED";
    delete syncLogInserts.oldKeyValue1;
    delete syncLogInserts.oldKeyValue2;
    delete syncLogInserts.oldKeyValue3;
  } else if (type === PRODUCT_ATTRIBUTE_CHANGE_TYPE.UPDATE)
    productAttributeQuery = updateQuery;
  else {
    productAttributeQuery = deleteQuery;
    syncLogInserts.operationType = "DELETED";
    delete syncLogInserts.newKeyValue1;
    delete syncLogInserts.newKeyValue2;
    delete syncLogInserts.newKeyValue3;
  }

  return productAttributeQuery;
};

function* updateProductAttribute(action: UpdateProductAttributeAction) {
  let actionType = UPDATE_PRODUCT_ATTRIBUTE;

  try {
    const productId = action.payload.productId;
    const newAttributeValueId = action.payload.newAttributeValueId;
    const oldAttributeValueId = action.payload.oldAttributeValueId;
    const type = action.payload.type;
    const isStoreSpecific = action.payload.isStoreSpecific;
    const isLocalized = action.payload.isLocalized;
    let syncLogInserts: SyncLogInserts = {
      tableName: "ProductAttributeValue",
      operationType: "UPDATED",
      keyName1: "attributeValueId",
      oldKeyValue1: oldAttributeValueId,
      newKeyValue1: newAttributeValueId,
      keyName2: "productId",
      oldKeyValue2: productId,
      newKeyValue2: productId,
    };
    let productAttributeQuery;
    const insertMode = type === PRODUCT_ATTRIBUTE_CHANGE_TYPE.INSERT;
    const deleteMode = type === PRODUCT_ATTRIBUTE_CHANGE_TYPE.DELETE;
    if (isStoreSpecific) {
      syncLogInserts.tableName = "ProductAttributeValueOverrides";
      syncLogInserts = {
        ...syncLogInserts,
        keyName3: "storeId",
        oldKeyValue3: action.payload.storeId,
        newKeyValue3: action.payload.storeId,
      };
      productAttributeQuery = getAttributeUpdateQuery(
        type,
        syncLogInserts,
        insertProductAttributeOverrideQuery,
        updateProductAttributeOverrideQuery,
        deleteProductAttributeOverrideQuery,
      );
    } else if (isLocalized) {
      syncLogInserts.tableName = "ProductAttributeLocalizedValue";
      syncLogInserts = {
        ...syncLogInserts,
        keyName3: "localeCode",
        oldKeyValue3: action.payload.localeCode,
        newKeyValue3: action.payload.localeCode,
      };
      productAttributeQuery = getAttributeUpdateQuery(
        type,
        syncLogInserts,
        insertProductLocalizedAttributeQuery,
        updateProductLocalizedAttributeQuery,
        deleteProductLocalizedAttributeQuery,
      );
    } else {
      productAttributeQuery = getAttributeUpdateQuery(
        type,
        syncLogInserts,
        insertProductAttributeQuery,
        updateProductAttributeQuery,
        deleteProductAttributeQuery,
      );
    }

    const query: GraphQLQuery = {
      query: productAttributeQuery,
      variables: {
        productId,
        newAttributeValueId,
        oldAttributeValueId,
        syncLogInserts: [syncLogInserts],
      },
    };
    if (isStoreSpecific) {
      query.variables = {
        ...query.variables,
        storeId: action.payload.storeId,
      };
    } else if (isLocalized) {
      query.variables = {
        ...query.variables,
        localeCode: action.payload.localeCode,
      };
    }
    if (insertMode) {
      delete query.variables.oldAttributeValueId;
    } else if (deleteMode) {
      delete query.variables.newAttributeValueId;
    }
    console.debug("query", query);

    const result: UpdateProductAttributeResponse = yield callGraphQLApi(
      actionType,
      GraphQLService.query,
      query,
    );

    /**
     * TODO : Add success and error handling
     */
    if (result) {
      if (result.type === actionType.SUCCESS) {
        if (result.payload.data) {
          if (
            insertMode &&
            result.payload.data.insert_ProductAttributeValue &&
            result.payload.data.insert_ProductAttributeValue.affected_rows
          ) {
          } else if (
            deleteMode &&
            result.payload.data.delete_ProductAttributeValue &&
            result.payload.data.delete_ProductAttributeValue.affected_rows
          ) {
          } else {
          }
        }
      }
    }
  } catch (e: any) {
    console.error(e);
    yield* put({ type: actionType.FAILURE, message: e.message });
  }
}

function* fetchProductsVariants(action: FetchProductsVariantsAction) {
  const actionType = FETCH_PRODUCTS_VARIANTS;
  const productId = action.payload.productId;
  const storeId = yield* select(selectCurrentStoreId);
  const localeCode = yield* select(selectCurrentLocale);
  const constName = Object.keys({ FETCH_PRODUCTS_VARIANTS })[0].toString();
  try {
    const endpoint = acquireEndpoint(constName, productId);
    const headersObj = {
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-locale-code": localeCode || "default",
    };

    yield* call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield* put({ type: FETCH_PRODUCTS_VARIANTS.FAILURE, message: e.message });
  }
}

function* UpdateProductVariantPublishState(
  action: UpdateProductVariantPublishStateAction,
) {
  yield* put({ type: UPDATE_PRODUCT_VARIANT_PUBLISH_STATE.REQUEST });
  const actionType = UPDATE_PRODUCT_VARIANT_PUBLISH_STATE;
  const productId = action.payload.productId;
  const variantId = action.payload.variantId;
  const variantCode = action.payload.variantCode;
  const storeId = yield* select(selectCurrentStoreId);
  const localeCode = yield* select(selectCurrentLocale);
  const catalogId = yield* select(selectCurrentCatalogId);
  const constName = Object.keys({
    UPDATE_PRODUCT_VARIANT_PUBLISH_STATE,
  })[0].toString();
  try {
    const endpoint = acquireEndpoint(constName, productId, variantId);
    const headersObj = {
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-locale-code": localeCode || "default",
    };

    const response = yield* call(
      callApi,
      actionType,
      putData,
      action.payload,
      endpoint,
      headersObj,
    );

    if (response?.payload) {
      yield* put(
        refreshProductFromList({
          productId,
          catalogId,
          localeCode,
          storeId,
        }),
      );
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "variantPublishedFlag.updated",
                defaultMessage: "{variantCode} has been updated.",
              },
              variables: {
                variantCode: `${variantCode}`,
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    }
    yield* put({ type: UPDATE_PRODUCT_VARIANT_PUBLISH_STATE.DONE });
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: UPDATE_PRODUCT_VARIANT_PUBLISH_STATE.FAILURE,
      message: e.message,
    });
  }
}

function* resetProductVariantsState() {
  try {
    yield* put({ type: RESET_PRODUCTS_VARIANTS_STATE });
  } catch (e: any) {
    console.error(e);
  }
}

function* updateRecentlyAddedProducts(action) {
  try {
    yield* put({
      type: ADD_MIGRATED_CATEGORIES_DATA,
      payload: action.payload,
      ids: action.ids,
    });
  } catch (e: any) {
    console.error(e);
  }
}
function* updateRecentlyAddedProductsNew(action) {
  try {
    yield* put({
      type: ADD_MIGRATED_CATEGORIES_NEW_DATA,
      payload: action.payload,
      categoryIds: action.categoryIds,
    });
  } catch (e: any) {
    console.error(e);
  }
}

function* updateProductPublishFlag(action: UpdateProductPublishFlagAction) {
  const actionType = UPDATE_PRODUCT_ISPUBLISHED_STATE;
  const localeCode = yield* select(selectCurrentLocale);
  const catalogId = yield* select(selectCurrentCatalogId);
  const { storeState, productIds, parentId } = action.payload;
  const currentStoreId = yield* select(selectCurrentStoreId);

  if (currentStoreId === null) {
    throw new Error("Missing currentStoreId");
  }
  if (localeCode === null) {
    throw new Error("Missing localeCode");
  }

  let result;
  yield* put({ type: UPDATE_PRODUCT_ISPUBLISHED_STATE.PENDING });
  const updatedProductIds = new Set();
  const constName = Object.keys({
    UPDATE_PRODUCT_ISPUBLISHED_STATE,
  })[0].toString();
  const condition =
    Array.isArray(productIds) &&
    Array.isArray(storeState) &&
    storeState &&
    productIds;
  try {
    if (condition) {
      for (const store of storeState) {
        const storeId = store.storeId;
        for (const productId of productIds) {
          const isPublished = store.value;
          updatedProductIds.add(productId);
          const endpoint = acquireEndpoint(constName, productId);
          const headersObj = {
            "x-store-id": storeId,
          };
          const flagValue =
            isPublished === "Publish"
              ? true
              : isPublished === "Unpublish"
                ? false
                : null;
          const payload = {
            isPublished: flagValue,
          };
          result = yield* call(
            callApi,
            actionType,
            putData,
            payload,
            endpoint,
            headersObj,
          );
          if (result?.type === actionType.SUCCESS) {
            updatedProductIds.add(productId);
            //Fetching the product details if the current storeId and product published storeId both are same
            if (currentStoreId === storeId) {
              const singleProductFetchPayload = {
                productId: productId,
                catalogId,
                localeCode: localeCode,
                storeId: storeId,
                isProductAddedFromClipBoard: true,
                isInCategory: flagValue,
                isProductRemoved: !flagValue ? true : false,
              };
              yield* put({
                type: FETCH_PRODUCT.REQUEST,
                payload: singleProductFetchPayload,
              });
            }
          }
        }
      }
    }
    yield* put({ type: RESET_UPDATE_PRODUCT_ISPUBLISHED_STATE });
    const updatedProductIdsArray = Array.from(updatedProductIds);
    if (result?.type === actionType.SUCCESS) {
      !parentId
        ? yield* put(
            LoadTopCategoriesByCatalogId(catalogId, localeCode, currentStoreId),
          )
        : yield* put(
            LoadChildCategories(
              catalogId,
              parentId,
              localeCode,
              currentStoreId,
            ),
          );

      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productPublishedFlag.updated",
                defaultMessage: " {updatedProductIds} has been updated.",
              },
              variables: {
                updatedProductIds: `${updatedProductIdsArray.join(",")}`,
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    }
    yield* put(setModalState(MultipleStoreModalId, false));
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: UPDATE_PRODUCT_ISPUBLISHED_STATE.FAILURE,
      message: e.message,
    });
  }
}

function* addProductInCategory(action: updateProductAddCategoryResponseAction) {
  try {
    let actionType = ADD_PRODUCT_IN_CATEGORY;
    const storeId = yield* select(selectCurrentStoreId);
    const isPublished = action.payload.isPublished;
    const parentId = action.payload.parentId;
    const catalogId = yield* select(selectCurrentCatalogId);
    const localeCode = yield* select(selectCurrentLocale);
    const constName = Object.keys({ ADD_PRODUCT_IN_CATEGORY })[0].toString();
    const categoryId = action.payload.categoryId;
    const endpoint = acquireEndpoint(constName, categoryId);

    if (storeId === null) {
      throw new Error("Missing storeId");
    }
    if (localeCode === null) {
      throw new Error("Missing localeCode");
    }

    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };
    const optionsObj = {
      showGenericError: false,
    };

    const response = yield* call(
      callApi,
      actionType,
      postData,
      action.payload,
      endpoint,
      headersObj,
      optionsObj,
    );
    if (response && response.payload.message === "success") {
      const singleProductFetchPayload = {
        productId: response.payload.productId,
        catalogId,
        localeCode: localeCode,
        storeId: storeId,
        isProductAddedFromVariationMgmt: true,
        isInCategory: isPublished,
      };
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "variationManagementModal.addProductToCurrentCategorySuccess",
                defaultMessage: "Product {productId} added to current category",
              },
              variables: {
                productId: `${response.payload.productId}`,
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
      !parentId
        ? yield* put(
            LoadTopCategoriesByCatalogId(catalogId, localeCode, storeId),
          )
        : yield* put(
            LoadChildCategories(catalogId, parentId, localeCode, storeId),
          );
      yield* put({
        type: FETCH_PRODUCT.REQUEST,
        payload: singleProductFetchPayload,
      });
    } else {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "variationManagementModal.addProductToCurrentCategoryFailure",
                defaultMessage:
                  "Unable to add {productId} to the current category",
              },
              variables: {
                productId: `${response.payload.productId}`,
              },
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    console.log("error", e);
  }
}

function* resetProductIsPublishedState() {
  try {
    yield* put({ type: RESET_UPDATE_PRODUCT_ISPUBLISHED_STATE });
  } catch (e: any) {
    console.log(e);
  }
}

function* removeProductInCategories(action) {
  try {
    const categoryId = action.payload.categoryId;
    const productId = action.payload.productId;
    const parentId = action.payload.parentId;
    const isFromCategory = action.payload.isFromCategory;
    const storeId = yield* select(selectCurrentStoreId);
    const catalogId = yield* select(selectCurrentCatalogId);
    const localeCode = yield* select(selectCurrentLocale);
    const actionType = REMOVE_PRODUCT_IN_CATEGORY;
    const constName = Object.keys({
      REMOVE_PRODUCT_IN_CATEGORY,
    })[0].toString();

    if (storeId === null) {
      throw new Error("Missing storeId");
    }
    if (localeCode === null) {
      throw new Error("Missing localeCode");
    }

    const endpoint = acquireEndpoint(constName, categoryId, productId);

    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };
    const optionsObj = {
      showGenericError: false,
    };

    const response = yield* call(
      callApi,
      actionType,
      deleteData,
      action.payload,
      endpoint,
      headersObj,
      optionsObj,
    );
    if (response && response.type === actionType.SUCCESS) {
      if (isFromCategory) {
        yield* put(
          addGlobalAlertState({
            alertsProps: [
              {
                descriptor: {
                  id: "variationManagementModal.removeProductFromCurrentCategorySuccess",
                  defaultMessage:
                    "Product {productId} removed from the current category",
                },
                variables: {
                  productId: `${productId}`,
                },
                severity: "success",
                variant: "standard",
              },
            ],
          }),
        );
      }
      !parentId
        ? yield* put(
            LoadTopCategoriesByCatalogId(catalogId, localeCode, storeId),
          )
        : yield* put(
            LoadChildCategories(catalogId, parentId, localeCode, storeId),
          );
    } else {
      yield* put({
        type: REMOVE_PRODUCT_IN_CATEGORY.FAILURE,
        payload: {
          productId: action.payload.productId,
        },
      });
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "variationManagementModal.removeProductFromCurrentCategoryFailure",
                defaultMessage:
                  "Unable to remove {productId} from the current category",
              },
              variables: {
                productId: `${productId}`,
              },
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: REMOVE_PRODUCT_IN_CATEGORY.FAILURE,
      payload: {
        productId: action.payload.productId,
      },
    });
    yield* put(
      addGlobalAlertState({
        alertsProps: [
          {
            descriptor: {
              id: "variationManagementModal.removeProductFromCurrentCategoryFailure",
              defaultMessage:
                "Unable to remove {productId} from the current category",
            },
            variables: {
              productId: `${action.payload.productId}`,
            },
            severity: "error",
            variant: "standard",
          },
        ],
      }),
    );
  }
}

// eslint-disable-next-line require-yield
function* updatePublishFlagInVariationModal(action) {
  try {
  } catch {}
}

function* verifyClipboardProductCode(action) {
  try {
    const actionType = VERIFY_CLIPBOARD_PRODUCT_CODES;
    const catalogId = action.payload.catalogId;
    const storeId = action.payload.storeId;
    const localeCode = yield* select(selectCurrentLocale);
    const constName = Object.keys({
      VERIFY_CLIPBOARD_PRODUCT_CODES,
    })[0].toString();
    const productCodes = action.payload.productCodes;
    //let numberOfApiCalls = productCodes.length;
    let invalidProductCodes = [] as string[];
    let successResponseCount = 0;
    const currentAction = action.payload.currentAction;

    const headersObj = {
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
      "x-locale-code": localeCode || "default",
    };

    const optionsObj = {
      showGenericError: false,
    };

    for (const code of productCodes) {
      const endpoint = acquireEndpoint(constName, code);
      const response = yield* call(
        callApi,
        actionType,
        getData,
        action.payload,
        endpoint,
        headersObj,
        optionsObj,
      );

      if (response.type === actionType.SUCCESS) {
        successResponseCount++;
      } else if (response.type === actionType.FAILURE) {
        invalidProductCodes.push(code);
      }
    }
    if (invalidProductCodes.length === productCodes.length) {
      yield* put({
        type: VERIFY_CLIPBOARD_PRODUCT_CODES.COMPLETE,
        payload: { invalidProductCodes },
      });
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "clipBoardArea.allCodesInvalid",
                defaultMessage:
                  "No part number is valid, please verify and try again",
              },
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
      // alert message of invalid
    } else if (invalidProductCodes.length && successResponseCount > 0) {
      yield* put({
        type: VERIFY_CLIPBOARD_PRODUCT_CODES.COMPLETE,
        payload: { invalidProductCodes, currentAction },
      });
    } else {
      yield* put({
        type: VERIFY_CLIPBOARD_PRODUCT_CODES.COMPLETE,
        payload: { invalidProductCodes, currentAction },
      });
    }
  } catch (error: any) {
    console.error(error);
    yield* put(
      addGlobalAlertState({
        alertsProps: [
          {
            descriptor: {
              id: "clipBoardArea.allCodesInvalid",
              defaultMessage:
                "No part number is valid, please verify and try again",
            },
            severity: "error",
            variant: "standard",
          },
        ],
      }),
    );
  }
}

function* fetchSingleProductAnalytics(
  action: loadSingleProductAnalyticsAction,
) {
  try {
    let actionType = LOAD_SINGLE_PRODUCT_ANALYTICS_STATE;
    const storeId = yield* select(selectCurrentStoreId);
    const catalogId = yield* select(selectCurrentCatalogId);
    const localeCode = yield* select(selectCurrentLocale);
    const productId = action.payload.productId;
    const constName = Object.keys({
      LOAD_SINGLE_PRODUCT_ANALYTICS_STATE,
    })[0].toString();
    const endpoint = acquireEndpoint(
      constName,
      LOAD_ANALYTICS_SERVICE_URL,
      productId,
    );

    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };

    const response = yield* call(
      callApi,
      actionType,
      postData,
      action.payload,
      endpoint,
      headersObj,
    );
    if (response && response.type === actionType.FAILURE) {
      yield* put(resetSingleProducAnalyticView(productId));
    }
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: LOAD_SINGLE_PRODUCT_ANALYTICS_STATE.FAILURE,
      message: e.message,
    });
  }
}

export function* watchResetProductVariantsState() {
  yield* takeEvery(RESET_PRODUCT_VARIANTS, resetProductVariantsState);
}

export function* watchUpdateProductVariantPublishState() {
  yield* takeEvery(
    PUT_PRODUCT_VARIANT_PUBLISH_STATE,
    UpdateProductVariantPublishState,
  );
}

export function* watchfetchProductsVariants() {
  yield* takeEvery(GET_PRODUCTS_VARIANTS, fetchProductsVariants);
}

export function* watchLoadProductDetails() {
  yield* takeEvery(LOAD_PRODUCT_DETAILS, getProductDetails);
}

export function* watchLoadProductDetailsForReport() {
  yield* takeEvery(LOAD_PRODUCT_DETAILS_FOR_REPORT, getProductDetailsForReport);
}

export function* watchUpdateProductPublishState() {
  yield* takeEvery(CHANGE_PRODUCT_PUBLISH_STATE, updateProductPublishState);
}

export function* watchUpdateProductPublishByDefaultState() {
  yield* takeEvery(
    CHANGE_PRODUCT_PUBLISH_BY_DEFAULT_STATE,
    updateProductPublishedByDefaultState,
  );
}
export function* watchUpdateProductPublishStateByStore() {
  yield* takeEvery(
    CHANGE_PRODUCT_PUBLISH_STATE_BY_STORE,
    updateProductPublishedStateByStore,
  );
}

export function* watchUpdateProductDeleteState() {
  yield* takeEvery(CHANGE_PRODUCT_DELETE_STATE, updateProductDeleteState);
}

export function* watchUpdateSingleProductDeleteState() {
  yield* takeEvery(
    CHANGE_SINGLE_PRODUCT_DELETE_STATE,
    updateSingleProductDeleteState,
  );
}

export function* watchUpdateProductColors() {
  yield* takeEvery(CHANGE_PRODUCT_COLORS, updateProductColors);
}

export function* watchUpdateLocaleProductPublish() {
  yield* takeEvery(CHANGE_LOCALE_PRODUCT_PUBLISH, updateLocaleProductPublish);
}
export function* watchCheckValidationProductCodes() {
  yield* takeEvery(GET_VALIDATION_PRODUCT_CODES, checkValidationProductCodes);
}

export function* watchLoadProductAnalytics() {
  yield* takeEvery(LOAD_PRODUCT_ANALYTICS, fetchProductAnalytics);
}

export function* watchLoadProductAnalyticsCategoryChange() {
  let task;
  while (true) {
    const action = yield* take(CATEGORY_CHANGE);
    if (task) {
      yield* cancel(task); // Cancel previous loading task if it's inflight
      yield* put(productLoadingCancelled());
    }
    task = yield* fork(
      fetchProductAnalytics,
      action as LoadProductAnalyticsAction,
    ); // Start a new product loading task
  }
}

export function* watchLoadCategoryByProductId() {
  yield* takeEvery(LOAD_CATEGORY_BY_PRODUCT_ID, loadCategoryByProductId);
}

export function* watchUpdateProductAttribute() {
  yield* takeEvery(CHANGE_PRODUCT_ATTRIBUTE, updateProductAttribute);
}

export function* watchUpdateRecentlyAddedProducts() {
  yield* takeEvery(ADD_MIGRATED_CATEGORIES, updateRecentlyAddedProducts);
}
export function* watchUpdateRecentlyAddedProductsNew() {
  yield* takeEvery(ADD_MIGRATED_CATEGORIES_NEW, updateRecentlyAddedProductsNew);
}

export function* watchAddProductInCategory() {
  yield* takeEvery(ADD_PRODUCT_IN_CATEGORY.REQUEST, addProductInCategory);
}

export function* watchRemoveProductInCategory() {
  yield* takeEvery(
    REMOVE_PRODUCT_IN_CATEGORY.REQUEST,
    removeProductInCategories,
  );
}

export function* watchUpdatePublishFlagInVariationModal() {
  yield* takeEvery(
    UPDATE_PUBLISH_FLAG.REQUEST,
    updatePublishFlagInVariationModal,
  );
}

export function* watchProductIsPublishedState() {
  yield* takeEvery(
    RESET_UPDATE_PRODUCT_ISPUBLISHED,
    resetProductIsPublishedState,
  );
}

export function* watchUpdateProductIsPublishState() {
  yield* takeEvery(UPDATE_PRODUCT_ISPUBLISHED, updateProductPublishFlag);
}

export function* watchVerifyClipboardProductCode() {
  yield* takeEvery(
    VERIFY_CLIPBOARD_PRODUCT_CODES.REQUEST,
    verifyClipboardProductCode,
  );
}

export function* watchLoadSingleProductAnalytics() {
  yield* takeEvery(FETCH_SINGLE_PRODUCT_ANALYTICS, fetchSingleProductAnalytics);
}

export function* watchCloseInventoryManagementModal() {
  let task;
  while (true) {
    const action = yield* take(MODAL_CLOSED);
    if (task) {
      yield* cancel(task); // Cancel previous loading task if it's inflight
      yield* put(productLoadingCancelled());
    }
    task = yield* fork(
      fetchProductsVariants,
      action as FetchProductsVariantsAction,
    ); // Start a new product loading task
  }
}
