import {
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
} from "typed-redux-saga/macro";
import {
  REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  CALL_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API,
  CALL_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API,
  FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_COMPLETE,
  SetLeadingImageForProductInCategoryAction,
  RemoveLeadingImageForProductInCategoryAction,
  GetVariantAttributesAction,
  FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID,
  FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES,
  RESET_VARIATION_ATTRIBUTES_FOR_PRODUCT,
} from "./LeadingImageTypes";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { callApi } from "../../utils/SagaUtils";
import { putData, getData } from "../../services/ApiService";
import { addGlobalAlertState } from "../global-alert/GlobalAlertActions";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "../catalog/CatalogSelectors";
import {
  createStoreSetLeadingImageAction,
  createStoreRemoveLeadingImageAction,
  createResetProdutctVariationAttributesAction,
} from "./LeadingImageActions";
import {
  productLoadingCancelled,
  refreshProductFromList,
} from "../product-list/ProductListActions";
import { MODAL_CLOSED } from "store/modal/ModalTypes";

function* setLeadingImageForProductInCategory(
  action: SetLeadingImageForProductInCategoryAction,
) {
  const productId = action.payload.productId;
  const categoryId = action.payload.categoryId;
  const leadingImage = action.payload.image.imageId;
  try {
    const actionType = CALL_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API;
    const storeId = yield* select(selectCurrentStoreId);
    const catalogId = yield* select(selectCurrentCatalogId);
    const localeCode = yield* select(selectCurrentLocale);
    const constName = Object.keys({
      CALL_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API,
    })[0].toString();
    const payload = {
      categoryId: categoryId,
      leadingImageId: leadingImage,
    };
    const headersObj = {
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
      "x-locale-code": localeCode,
    };

    const endpoint = acquireEndpoint(constName, productId);
    const result = yield* call(
      callApi,
      actionType,
      putData,
      payload,
      endpoint,
      headersObj,
    );
    if (result?.type === actionType.SUCCESS) {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "leadingColorModal.setLeadingImageForProductInCategorySuccess",
                defaultMessage:
                  "Updated leading image value to {leadingImage} for {productId}",
              },
              variables: {
                productId,
                leadingImage,
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
      yield* put(
        createStoreSetLeadingImageAction({
          productId,
          categoryId,
          image: action.payload.image,
        }),
      );
      yield* put(
        refreshProductFromList({
          productId,
          catalogId,
          localeCode,
          storeId,
        }),
      );
    }
  } catch (e: any) {
    yield* put(
      addGlobalAlertState({
        alertsProps: [
          {
            descriptor: {
              id: "leadingColorModal.setLeadingImageForProductInCategoryFail",
              defaultMessage:
                "Unable to update leading image value of {productId}",
            },
            variables: {
              productId,
            },
            severity: "error",
            variant: "standard",
          },
        ],
      }),
    );
    console.error(e);
    yield* put({
      type: CALL_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API.FAILURE,
      message: e.message,
    });
  }
}

function* removeLeadingImageForProductInCategory(
  action: RemoveLeadingImageForProductInCategoryAction,
) {
  try {
    const actionType = CALL_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API;
    const productId = action.payload.productId;
    const categoryId = action.payload.categoryId;
    const storeId = yield* select(selectCurrentStoreId);
    const catalogId = yield* select(selectCurrentCatalogId);
    const localeCode = yield* select(selectCurrentLocale);
    const constName = Object.keys({
      CALL_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API,
    })[0].toString();
    const endpoint = acquireEndpoint(constName, productId);
    const payload = {
      categoryId: categoryId,
      leadingImageId: null,
    };
    const headersObj = {
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
      "x-locale-code": localeCode,
    };

    const result = yield* call(
      callApi,
      actionType,
      putData,
      payload,
      endpoint,
      headersObj,
    );
    if (result?.type === actionType.SUCCESS) {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "leadingColorModal.removeLeadingImageForProductInCategorySuccess",
                defaultMessage:
                  "Leading image removed from the current category/product",
              },
              variables: {},
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
      yield* put(
        createStoreRemoveLeadingImageAction({
          productId,
          categoryId,
        }),
      );
      yield* put(
        refreshProductFromList({
          productId,
          catalogId,
          localeCode,
          storeId,
        }),
      );
    }
  } catch (e: any) {
    yield* put(
      addGlobalAlertState({
        alertsProps: [
          {
            descriptor: {
              id: "leadingColorModal.removeLeadingImageForProductInCategoryFail",
              defaultMessage:
                "Unable to remove leading image from the current category/product",
            },
            variables: {},
            severity: "error",
            variant: "standard",
          },
        ],
      }),
    );
    console.error(e);
    yield* put({
      type: CALL_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API.FAILURE,
      message: e.message,
    });
  }
}

function* getVariationAttributes(action: GetVariantAttributesAction) {
  try {
    const baseProductId = action.payload.baseProductId;
    const actionType =
      FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID;
    const constName = Object.keys({
      FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID,
    })[0].toString();
    const endpoint = acquireEndpoint(constName, baseProductId);
    const result = yield* call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
    );
    if (result?.type === actionType.SUCCESS) {
      yield* put({
        type: FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_COMPLETE,
        payload: result.payload?.variationAttributes,
      });
    }
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID.FAILURE,
      message: e.message,
    });
  }
}

function* resetVariationAttributesState() {
  try {
    yield* put(createResetProdutctVariationAttributesAction());
  } catch (e: any) {
    console.error(e);
  }
}

export function* watchGetLeadingImageVariationAttributes() {
  yield* takeEvery(
    FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES,
    getVariationAttributes,
  );
}

export function* watchSetLeadingImageForProductInCategory() {
  yield* takeEvery(
    SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
    setLeadingImageForProductInCategory,
  );
}

export function* watchRemoveLeadingImageForProductInCategory() {
  yield* takeEvery(
    REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
    removeLeadingImageForProductInCategory,
  );
}

export function* watchResetVariationAttributesForProduct() {
  yield* takeEvery(
    RESET_VARIATION_ATTRIBUTES_FOR_PRODUCT,
    resetVariationAttributesState,
  );
}

export function* watchCloseImageManagementModal() {
  let task;
  while (true) {
    const action = yield* take(MODAL_CLOSED);
    if (task) {
      yield* cancel(task); // Cancel previous loading task if it's inflight
      yield* put(productLoadingCancelled());
    }
    task = yield* fork(
      getVariationAttributes,
      action as GetVariantAttributesAction,
    ); // Start a new product loading task
  }
}
