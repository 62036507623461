import React from "react";
import {
  IconButton,
  makeStyles,
  Theme,
  createStyles,
  Divider,
} from "@material-ui/core";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import clsx from "clsx";
import OverlayTabs from "./OverlayTabs";
import AddAndCopyProducts from "./AddAndCopyProducts";
import BundleRelated from "./BundleRelated";
import JobRelated from "./JobRelated";
import Icons from "./common/Icons";
// import CoreSizesManagementLink from './core-sizes-management/CoreSizesManagementLink'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overlayHeader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: " 44px",
    },
    menuButton: {
      position: "absolute",
      left: 0,
      padding: "10px",
      height: 45,
      width: 45,
      marginRight: 5,
    },
    hide: {
      display: "none",
    },
    header: {
      fontWeight: 600,
    },
    arrowIcons: {
      width: 20,
      height: 20,
    },
  }),
);

interface Props {
  onDrawerOpen: () => void;
  isOpen: boolean;
  categoryId: string;
}

const ControlPanel: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { onDrawerOpen, isOpen } = props;
  const classes = useStyles();

  const DrawerOpenHandler = () => {
    onDrawerOpen();
  };
  const showUnimplemented =
    window.localStorage.getItem("showUnimplemented") === "true";

  return (
    <>
      <div className={classes.overlayHeader}>
        <IconButton
          aria-label="open drawer"
          onClick={DrawerOpenHandler}
          className={clsx(classes.menuButton, isOpen && classes.hide)}
        >
          <Icons classes={classes.arrowIcons} iconId="RightArrowSmallIcon" />
        </IconButton>
        <Typography variant="header">
          {intl.formatMessage({
            id: "controlPanel.title",
            defaultMessage: "Overlay",
          })}
        </Typography>
      </div>
      <Divider />
      <OverlayTabs />
      {/* <CoreSizesManagementLink  /> */}
      <AddAndCopyProducts categoryId={props.categoryId} />
      <JobRelated />
      {showUnimplemented && (
        <>
          <BundleRelated />
        </>
      )}
    </>
  );
};

export default ControlPanel;
