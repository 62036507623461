import { call, select, takeEvery } from "typed-redux-saga/macro";

import { FETCH_PRODUCT_ALERTS_LIST } from "./ProductAlertsListType";

import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { callApi } from "../../utils/SagaUtils";

import { getData } from "../../services/ApiService";
import { getUserEmail } from "../../services/UserService";

function* getProductAlertsList() {
  try {
    const actionType = FETCH_PRODUCT_ALERTS_LIST;
    const constName = Object.keys({ FETCH_PRODUCT_ALERTS_LIST })[0].toString();
    const email = yield* select(getUserEmail);
    const endpoint = acquireEndpoint(constName, email);
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

export function* watchLoadProductAlertsList() {
  yield* takeEvery(FETCH_PRODUCT_ALERTS_LIST.REQUEST, getProductAlertsList);
}
