import { call, put } from "typed-redux-saga/macro";
import {
  GraphQLHttpResponse,
  GraphQLResponseFailureAction,
  GraphQLResponseSuccessAction,
  GraphQLServiceFn,
  GraphQLQuery,
} from "../services/GraphQLTypes";
import {
  ApiServiceFn,
  // ApiServiceHttpResponse,
  ApiServiceResponseSuccessAction,
  ApiServiceResponseFailureAction,
  HeaderType,
} from "../services/ApiServiceTypes";

interface ActionTypeObject {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
}

export function* callGraphQLApi(
  actionType: ActionTypeObject,
  serviceFn: GraphQLServiceFn,
  query: GraphQLQuery,
) {
  try {
    const response: GraphQLHttpResponse = yield* call(serviceFn, query);
    if (response.data === undefined && response.errors !== undefined) {
      throw new Error(response.errors[0].message);
    }
    const successAction: GraphQLResponseSuccessAction = {
      payload: {
        variables: query.variables,
        ...response,
      },
      type: actionType.SUCCESS,
    };
    yield* put(successAction);
    return successAction;
  } catch (error: any) {
    const failureAction: GraphQLResponseFailureAction = {
      error: error.message,
      type: actionType.FAILURE,
    };
    yield* put(failureAction);
    return failureAction;
  }
}

export interface ResponsePayloadIds {
  catalogId?: string;
  nodeId?: string;
}

export function* callApi(
  actionType: ActionTypeObject,
  serviceFn: ApiServiceFn,
  payload: any | null,
  endpoint: string,
  headersObj?: HeaderType,
  options?: {
    showGenericError: boolean;
  },
): Generator<any> {
  try {
    let response = yield* call(serviceFn, payload, endpoint, headersObj);

    if (response !== undefined) {
      if (headersObj && headersObj["x-store-id"] && response) {
        response.storeId = headersObj["x-store-id"];
      }

      if (payload?.catalogId) {
        response.catalogId = payload.catalogId;
      }

      if (payload?.nodeId) {
        response.nodeId = payload.nodeId;
      }

      const successAction: ApiServiceResponseSuccessAction = {
        payload: {
          ...response,
          ...payload,
        },
        endpoint: endpoint,
        type: actionType.SUCCESS,
      };
      yield* put(successAction);
      return successAction;
    } else {
      const failureAction: ApiServiceResponseFailureAction = {
        endpoint: endpoint,
        type: actionType.FAILURE,
        showGenericError: options?.showGenericError,
      };
      yield* put(failureAction);
      return failureAction;
    }
  } catch (error: any) {
    const errMessage = error.response?.data?.reason
      ? error.response?.data?.message
      : undefined;
    const failureAction: ApiServiceResponseFailureAction = {
      reason: error.response?.data?.reason,
      message: errMessage,
      endpoint: endpoint,
      type: actionType.FAILURE,
      showGenericError: options?.showGenericError,
    };
    yield* put(failureAction);
    return failureAction;
  }
}
export function* delay(ms) {
  yield new Promise((res) => setTimeout(res, ms));
  return;
}
