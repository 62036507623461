import { takeEvery } from "typed-redux-saga/macro";
import ReactGA from "react-ga";
import {
  UPDATE_SELECTED_OVERLAY,
  updateSelectedOverlayAction,
} from "../overlay/OverlayTypes";
import { SET_MODAL_STATE, setModalStateAction } from "../modal/ModalTypes";
import {
  UPDATE_SELECTED_PRODUCTS_CODE,
  CHANGE_PRODUCT_ATTRIBUTE,
  CHANGE_PRODUCT_PUBLISH_STATE,
  CHANGE_PRODUCT_DELETE_STATE,
  CHANGE_LOCALE_PRODUCT_PUBLISH,
  CHANGE_PRODUCT_PUBLISH_STATE_SFCC,
  CHANGE_PRODUCT_COLORS,
  CHANGE_PRODUCT_PUBLISH_BY_DEFAULT_STATE,
  CHANGE_PRODUCT_PUBLISH_STATE_BY_STORE,
  CHANGE_SINGLE_PRODUCT_DELETE_STATE,
} from "../product/ProductTypes";
import {
  UPDATE_NUMBER_OF_PINNED_PRODUCTS,
  CHANGE_PRODUCT_LIST_SEQUENCE,
} from "../product-list/ProductListTypes";
import { CHANGE_PRODUCTS_CATEGORY } from "../find-products/FindProductsTypes";
import {
  CHANGE_ATTRIBUTE_VALUE_SEQUENCES,
  CHANGE_FACET_SEQUENCES,
  CHANGE_INHERITED_FACET,
  CHANGE_SFCC_FACET_SEQUENCES,
  CHANGE_FACET_BUCKET_SEQUENCES,
  CHANGE_FACET_BUCKET_DESCRIPTION,
  CHANGE_FACET_BUCKET,
} from "../facets/FacetTypes";
import {
  CHANGE_ATTRIBUTE_VALUE_CODE,
  CHANGE_ATTRIBUTE_VALUE_DESCRIPTION,
} from "../attributes/AttributeTypes";
import { OVERLAY_TABS } from "../../utils/Constants";

type actionType = updateSelectedOverlayAction | setModalStateAction;

const actions = [
  UPDATE_SELECTED_OVERLAY,
  SET_MODAL_STATE,
  UPDATE_SELECTED_PRODUCTS_CODE,
  UPDATE_NUMBER_OF_PINNED_PRODUCTS,
  CHANGE_PRODUCT_ATTRIBUTE,
  CHANGE_PRODUCT_LIST_SEQUENCE,
  CHANGE_PRODUCTS_CATEGORY,
  CHANGE_PRODUCT_PUBLISH_STATE,
  CHANGE_PRODUCT_DELETE_STATE,
  CHANGE_LOCALE_PRODUCT_PUBLISH,
  CHANGE_PRODUCT_PUBLISH_STATE_SFCC,
  CHANGE_PRODUCT_COLORS,
  CHANGE_PRODUCT_PUBLISH_BY_DEFAULT_STATE,
  CHANGE_PRODUCT_PUBLISH_STATE_BY_STORE,
  CHANGE_SINGLE_PRODUCT_DELETE_STATE,
  CHANGE_ATTRIBUTE_VALUE_SEQUENCES,
  CHANGE_FACET_SEQUENCES,
  CHANGE_INHERITED_FACET,
  CHANGE_SFCC_FACET_SEQUENCES,
  CHANGE_FACET_BUCKET_SEQUENCES,
  CHANGE_FACET_BUCKET_DESCRIPTION,
  CHANGE_FACET_BUCKET,
  CHANGE_ATTRIBUTE_VALUE_CODE,
  CHANGE_ATTRIBUTE_VALUE_DESCRIPTION,
];

function emitGoogleAnalyticsEvent(action: actionType) {
  const type = action.type;
  switch (type) {
    case CHANGE_ATTRIBUTE_VALUE_CODE:
    case CHANGE_ATTRIBUTE_VALUE_DESCRIPTION: {
      ReactGA.event({
        category: "Facet",
        action: "Change Facet Attribute Value Action",
      });
      break;
    }
    case CHANGE_FACET_SEQUENCES:
    case CHANGE_SFCC_FACET_SEQUENCES: {
      ReactGA.event({
        category: "Facet",
        action: "Change Facet Sequences Action",
      });
      break;
    }
    case CHANGE_ATTRIBUTE_VALUE_SEQUENCES: {
      ReactGA.event({
        category: "Facet",
        action: "Change Facet Attribute Value Sequences Action",
      });
      break;
    }
    case CHANGE_INHERITED_FACET: {
      ReactGA.event({
        category: "Facet",
        action: "Change Inherited Facet Action",
      });
      break;
    }
    case CHANGE_FACET_BUCKET_SEQUENCES: {
      ReactGA.event({
        category: "Facet",
        action: "Change Facet Bucket Sequences Action",
      });
      break;
    }
    case CHANGE_FACET_BUCKET_DESCRIPTION: {
      ReactGA.event({
        category: "Facet",
        action: "Change Facet Bucket Description Action",
      });
      break;
    }
    case CHANGE_FACET_BUCKET: {
      ReactGA.event({
        category: "Facet",
        action: "Change Facet Bucket Action",
      });
      break;
    }
    case CHANGE_PRODUCTS_CATEGORY: {
      ReactGA.event({
        category: "Category",
        action: "Assign Products To Category Action",
      });
      break;
    }
    // case UPDATE_SELECTED_PRODUCTS_CODE: {
    //   ReactGA.event({
    //     category: "Product",
    //     action: "Copy Products Action",
    //   });
    //   break;
    // }
    case CHANGE_PRODUCT_ATTRIBUTE: {
      ReactGA.event({
        category: "Product",
        action: "Change Product Attribute Action",
      });
      break;
    }
    case CHANGE_PRODUCT_DELETE_STATE:
    case CHANGE_SINGLE_PRODUCT_DELETE_STATE: {
      ReactGA.event({
        category: "Product",
        action: "Change Product Delete State Action",
      });
      break;
    }
    case CHANGE_PRODUCT_PUBLISH_BY_DEFAULT_STATE:
    case CHANGE_PRODUCT_PUBLISH_STATE_BY_STORE:
    case CHANGE_LOCALE_PRODUCT_PUBLISH:
    case CHANGE_PRODUCT_PUBLISH_STATE:
    case CHANGE_PRODUCT_PUBLISH_STATE_SFCC: {
      ReactGA.event({
        category: "Product",
        action: "Change Product Publish State Action",
      });
      break;
    }
    case CHANGE_PRODUCT_COLORS: {
      ReactGA.event({
        category: "Product",
        action: "Change Product Colors Action",
      });
      break;
    }
    case UPDATE_NUMBER_OF_PINNED_PRODUCTS: {
      ReactGA.event({
        category: "ProductList",
        action: "Set Pinned Products Action",
      });
      break;
    }
    case CHANGE_PRODUCT_LIST_SEQUENCE: {
      ReactGA.event({
        category: "ProductList",
        action: "Save Product List Sequence Action",
      });
      break;
    }
    case UPDATE_SELECTED_OVERLAY: {
      const updateOverlayAction = action as updateSelectedOverlayAction;
      const overlay = updateOverlayAction.overlay;
      let modalview = "";
      switch (overlay) {
        case OVERLAY_TABS.PRODUCTS: {
          modalview = "Products Overlay";
          break;
        }
        case OVERLAY_TABS.ANALYTICS_OVERLAY: {
          modalview = "Analytics Overlay";
          break;
        }
        case OVERLAY_TABS.RATINGS: {
          modalview = "Social Overlay";
          break;
        }
        case OVERLAY_TABS.CATEGORIES_OVERLAY: {
          modalview = "Categories Overlay";
          break;
        }
        case OVERLAY_TABS.INVENTORY_OVERLAY: {
          modalview = "Inventory Overlay";
          break;
        }
        case OVERLAY_TABS.ATTRIBUTES_OVERLAY: {
          modalview = "Attributes Overlay";
          break;
        }
      }
      ReactGA.modalview(modalview);
      break;
    }
    case SET_MODAL_STATE: {
      const setModalAction = action as setModalStateAction;
      const { modalId, isOpen } = setModalAction.payload;
      if (isOpen) {
        ReactGA.modalview(modalId);
      }
      break;
    }
  }
}

export function* watchGoogleAnalyticsEvent() {
  yield* takeEvery(actions, emitGoogleAnalyticsEvent);
}
