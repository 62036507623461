import { all, call, put, select, takeEvery } from "typed-redux-saga/macro";
import {
  FETCH_UNIFIED_SORTING_RULES_LIST,
  LOAD_ATTRIBUTES_LIST,
  LOAD_BUSINESS_RULES_SERVICE_URL,
  LOAD_UNIFIED_SORTING_RULES_LIST,
  SAVE_UNIFIED_BUSINESS_RULES,
  SAVE_UNIFIED_BUSINESS_RULES_DETAILS,
  FETCH_ATTRIBUTES_LIST,
  GET_BUSINESS_RULES_DETAILS_BY_ID,
  FETCH_BUSINESS_RULES_DETAILS_BY_ID,
  UPDATE_BUSINESS_RULES,
  UPDATE_BUSINESS_RULES_DETAILS_BY_RULE_ID,
  DELETE_BUSINESS_RULE,
  DELETE_BUSINESS_RULE_BY_RULE_ID,
  LOAD_JOBS_LIST,
  FETCH_JOBS_LIST,
  FETCH_AUTOMATION_LIST,
  LOAD_AUTOMATION_LIST,
  DELETE_AUTOMATION_RULE,
  DELETE_AUTOMATION_RULE_BY_JOB_ID,
  SAVE_AUTOMATION_RULES,
  SAVE_AUTOMATION_RULES_DETAILS,
  GET_AUTOMATION_RULES_DETAILS_BY_ID,
  FETCH_AUTOMATION_RULES_DETAILS_BY_ID,
  UPDATE_AUTOMATION_RULES,
  UPDATE_AUTOMATION_RULES_DETAILS_BY_RULE_ID,
  FETCH_CATEGORY_DETAILS_FOR_MULTIPLE_IDS,
  FETCH_AUTOMATION_RULES_CATEGORY_DETAILS_BY_ID,
  LOAD_PLATFORM_INDEPENDENT_URL,
  FETCH_PENDING_JOBS_LIST,
  FETCH_SYSTEM_GENERATED_SORTING_RULES_LIST,
  LOAD_SYSTEM_GENERATED_SORTING_RULES_LIST,
  LoadSystemGeneratedSortingRulesListAction,
  DeleteScheduleAction,
  FETCH_ATTRIBUTES,
  headers,
  FETCH_PRODUCT_ATTRIBUTES,
  AttributesList,
} from "./BusinessRulesListTypes";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { callApi } from "../../utils/SagaUtils";
import {
  deleteData,
  getData,
  postData,
  putData,
} from "../../services/ApiService";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
  selectStoreListIds,
} from "../store-list/StoreListSelectors";
import { addGlobalAlertState } from "../global-alert/GlobalAlertActions";
import { storeAllAttributes } from "./BusinessRulesListActions";

function* getUnifiedSortingRulesList() {
  try {
    const actionType = FETCH_UNIFIED_SORTING_RULES_LIST;
    const constName = Object.keys({
      FETCH_UNIFIED_SORTING_RULES_LIST,
    })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;

    const endpoint = acquireEndpoint(constName, serviceType);
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* getAttributesList() {
  try {
    const actionType = FETCH_ATTRIBUTES_LIST;
    const constName = Object.keys({
      FETCH_ATTRIBUTES_LIST,
    })[0].toString();
    //const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;
    const storeId = yield* select(selectCurrentStoreId);
    const localeCode = yield* select(selectCurrentLocale);
    const headersObj = {
      "x-store-id": storeId,
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
    };
    const endpoint = acquireEndpoint(constName);
    yield* call(callApi, actionType, getData, null, endpoint, headersObj);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* fetchProductAttributes(action) {
  try {
    const actionType = FETCH_PRODUCT_ATTRIBUTES;
    const constName = Object.keys({
      FETCH_PRODUCT_ATTRIBUTES,
    })[0].toString();
    const localeCode = yield* select(selectCurrentLocale);
    const storeIdsList = yield* select(selectStoreListIds);
    const endpoint = acquireEndpoint(constName, "true");
    const headers: headers[] = [];
    for (let i = 0; i < storeIdsList.length; i++) {
      const headersObj = {
        "x-store-id": storeIdsList[i].storeId,
        "x-locale-code": localeCode || "-1",
        "x-currency-code": "USD",
      };
      headers.push(headersObj);
    }
    const responses = yield* all(
      headers?.map((header) =>
        call(callApi, actionType, postData, action.payload, endpoint, header),
      ),
    );
    const attributesList = responses?.map(
      (response) => response.payload.attributes,
    );
    const attrs: AttributesList[] = [];
    attributesList?.forEach((attrList) => {
      attrList.forEach((attr) => {
        attrs.push({
          ...attr,
        });
      });
    });
    yield* put(storeAllAttributes({ attributes: attrs }));
  } catch (error: any) {
    console.log("error", error);
  }
}

function* saveUnifiedBusinessRules(action: any) {
  try {
    const actionType = SAVE_UNIFIED_BUSINESS_RULES_DETAILS;
    const constName = Object.keys({
      SAVE_UNIFIED_BUSINESS_RULES_DETAILS,
    })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;

    const endpoint = acquireEndpoint(constName, serviceType);
    yield* call(callApi, actionType, postData, action.payload, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* getUnifiedBusinessRulesDetailsById(action: any) {
  try {
    const actionType = FETCH_BUSINESS_RULES_DETAILS_BY_ID;
    const constName = Object.keys({
      FETCH_BUSINESS_RULES_DETAILS_BY_ID,
    })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;

    const endpoint = acquireEndpoint(constName, serviceType, action.payload);
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* updateUnifiedBusinessRules(action: any) {
  try {
    const actionType = UPDATE_BUSINESS_RULES_DETAILS_BY_RULE_ID;
    const constName = Object.keys({
      UPDATE_BUSINESS_RULES_DETAILS_BY_RULE_ID,
    })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;

    const endpoint = acquireEndpoint(
      constName,
      serviceType,
      action.payload.ruleId,
    );
    yield* call(callApi, actionType, putData, action.payload, endpoint);
  } catch (error: any) {
    console.log(error);
  }
}

function* deleteBusinessRuleByRuleId(action: any) {
  yield* put({ type: DELETE_BUSINESS_RULE_BY_RULE_ID.REQUEST });
  try {
    const actionType = DELETE_BUSINESS_RULE_BY_RULE_ID;
    const constName = Object.keys({
      DELETE_BUSINESS_RULE_BY_RULE_ID,
    })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;
    const endpoint = acquireEndpoint(constName, serviceType, action.payload);
    const response = yield* call(
      callApi,
      actionType,
      deleteData,
      null,
      endpoint,
    );
    if (response?.type === actionType.SUCCESS) {
      yield* put({ type: DELETE_BUSINESS_RULE_BY_RULE_ID.SUCCESS });
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "automationOperations.deleteSortingRuleSuccess",
                defaultMessage: "Sorting rule deleted",
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    }
    yield* put({ type: DELETE_BUSINESS_RULE_BY_RULE_ID.DONE });
  } catch (error: any) {
    console.log(error);
  }
}

function* loadJobsList(action: any) {
  try {
    const actionType = FETCH_JOBS_LIST;
    const constName = Object.keys({
      FETCH_JOBS_LIST,
    })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;
    const endpoint = acquireEndpoint(constName, serviceType, action.payload);
    yield* all([
      call(callApi, actionType, getData, null, endpoint),
      call(loadPendingJobs),
    ]);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* loadPendingJobs() {
  try {
    const actionType = FETCH_PENDING_JOBS_LIST;
    const constName = Object.keys({ FETCH_PENDING_JOBS_LIST })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;

    const endpoint = acquireEndpoint(constName, serviceType);
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}
//yield put({ type: actionType.FAILURE, message: error.message });
function* getAutomationRulesList() {
  try {
    const actionType = FETCH_AUTOMATION_LIST;
    const constName = Object.keys({ FETCH_AUTOMATION_LIST })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;

    const endpoint = acquireEndpoint(constName, serviceType);
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* deleteAutomationRuleByJobId(action: DeleteScheduleAction) {
  try {
    const actionType = DELETE_AUTOMATION_RULE_BY_JOB_ID;
    const constName = Object.keys({
      DELETE_AUTOMATION_RULE_BY_JOB_ID,
    })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;
    const jobId = action.payload.jobId;
    const endpoint = acquireEndpoint(constName, serviceType, jobId);
    const result = yield* call(callApi, actionType, deleteData, null, endpoint);
    if (result?.type === actionType.SUCCESS) {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "automationOperations.deleteAutomationJobSuccess",
                defaultMessage: "{jobName} deleted successfully.",
              },
              variables: {
                jobName: `${action.payload.name}`,
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (error: any) {
    console.log(error);
  }
}
function* saveAutomationRules(action: any) {
  try {
    const actionType = SAVE_AUTOMATION_RULES_DETAILS;
    const constName = Object.keys({
      SAVE_AUTOMATION_RULES_DETAILS,
    })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;

    const endpoint = acquireEndpoint(constName, serviceType, action.payload);
    yield* call(callApi, actionType, postData, action.payload, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* getAutomationRuleById(action: any) {
  try {
    const actionType = FETCH_AUTOMATION_RULES_DETAILS_BY_ID;
    const constName = Object.keys({
      FETCH_AUTOMATION_RULES_DETAILS_BY_ID,
    })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;

    const endpoint = acquireEndpoint(constName, serviceType, action.payload);
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* updateAutomationRule(action: any) {
  try {
    const actionType = UPDATE_AUTOMATION_RULES_DETAILS_BY_RULE_ID;
    const constName = Object.keys({
      UPDATE_AUTOMATION_RULES_DETAILS_BY_RULE_ID,
    })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;

    const endpoint = acquireEndpoint(
      constName,
      serviceType,
      action.payload.jobId,
    );
    yield* call(callApi, actionType, putData, action.payload, endpoint);
  } catch (error: any) {
    console.log(error);
  }
}

// Worker saga for the first API call
function* getCategoryDataById(categoryData) {
  try {
    const actionType = FETCH_AUTOMATION_RULES_CATEGORY_DETAILS_BY_ID;
    const constName = Object.keys({
      FETCH_AUTOMATION_RULES_CATEGORY_DETAILS_BY_ID,
    })[0].toString();
    const storeId = categoryData.storeId;
    const catalogId = categoryData.catalogId;
    const localeCode = categoryData.localeCode;
    const serviceType = LOAD_PLATFORM_INDEPENDENT_URL;
    const headersObj = {
      "x-store-id": storeId,
      "x-locale-code": localeCode || "default",
      "x-catalog-id": catalogId,
    };
    const endpoint = acquireEndpoint(
      constName,
      serviceType,
      categoryData.categoryId,
    );
    yield* call(callApi, actionType, getData, null, endpoint, headersObj);
  } catch (error) {
    console.log(error);
  }
}

function* fetchDataForMultipleIds(action) {
  try {
    const { payload } = action;

    // Create an array of call effects for each API call
    const apiCallEffects = payload.map((categoryData) =>
      all([call(getCategoryDataById, categoryData)]),
    );
    // Execute all API calls in parallel
    yield* all(apiCallEffects);
  } catch (error: any) {
    console.log(error);
  }
}
function* getSystemGeneratedSortingRules(
  action: LoadSystemGeneratedSortingRulesListAction,
) {
  try {
    const actionType = FETCH_SYSTEM_GENERATED_SORTING_RULES_LIST;
    const constName = Object.keys({
      FETCH_SYSTEM_GENERATED_SORTING_RULES_LIST,
    })[0].toString();
    const serviceType = LOAD_BUSINESS_RULES_SERVICE_URL;
    const endpoint = acquireEndpoint(constName, serviceType);
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (error) {
    yield* put({ type: FETCH_SYSTEM_GENERATED_SORTING_RULES_LIST.FAILURE });
  }
}

export function* watchSystemGeneratedSortingRules() {
  yield* takeEvery(
    LOAD_SYSTEM_GENERATED_SORTING_RULES_LIST,
    getSystemGeneratedSortingRules,
  );
}

export function* watchUnifiedSortingRulesList() {
  yield* takeEvery(LOAD_UNIFIED_SORTING_RULES_LIST, getUnifiedSortingRulesList);
}
export function* watchAttributesList() {
  yield* takeEvery(LOAD_ATTRIBUTES_LIST, getAttributesList);
}
export function* watchSaveUnifiedBusinessRulesDetails() {
  yield* takeEvery(SAVE_UNIFIED_BUSINESS_RULES, saveUnifiedBusinessRules);
}
export function* watchGetUnifiedBusinessRulesDetailsById() {
  yield* takeEvery(
    GET_BUSINESS_RULES_DETAILS_BY_ID,
    getUnifiedBusinessRulesDetailsById,
  );
}
export function* watchUpdateUnifiedBusinessRulesDetails() {
  yield* takeEvery(UPDATE_BUSINESS_RULES, updateUnifiedBusinessRules);
}
export function* watchDeleteBusinessRuleById() {
  yield* takeEvery(DELETE_BUSINESS_RULE, deleteBusinessRuleByRuleId);
}
export function* watchLoadJobsList() {
  yield* takeEvery(LOAD_JOBS_LIST, loadJobsList);
}
export function* watchLoadAutomationRulesList() {
  yield* takeEvery(LOAD_AUTOMATION_LIST, getAutomationRulesList);
}
export function* watchDeleteAutomationRuleByJobId() {
  yield* takeEvery(DELETE_AUTOMATION_RULE, deleteAutomationRuleByJobId);
}
export function* watchSaveAutomationRules() {
  yield* takeEvery(SAVE_AUTOMATION_RULES, saveAutomationRules);
}
export function* watchGetAutomationRuleByJobId() {
  yield* takeEvery(GET_AUTOMATION_RULES_DETAILS_BY_ID, getAutomationRuleById);
}
export function* watchUpdateAutomationRule() {
  yield* takeEvery(UPDATE_AUTOMATION_RULES, updateAutomationRule);
}
export function* watchFetchDataForMultipleCategoryIds() {
  yield* takeEvery(
    FETCH_CATEGORY_DETAILS_FOR_MULTIPLE_IDS,
    fetchDataForMultipleIds,
  );
}

export function* watchFetchAttributes() {
  yield* takeEvery(FETCH_ATTRIBUTES, fetchProductAttributes);
}
