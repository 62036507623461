import { takeEvery, put, call, select } from "typed-redux-saga/macro";
import { callApi } from "../../utils/SagaUtils";
import {
  LoadCatalogListAction,
  LoadAllCatalogsAction,
  LOAD_CATALOG_LIST,
  FETCH_CATALOG_LIST,
  LOAD_ALL_CATALOGS,
  FETCH_ALL_CATALOGS,
} from "./CatalogTypes";
import { getData } from "../../services/ApiService";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { selectCurrentLocale } from "../store-list/StoreListSelectors";

export function* watchLoadCatalogList() {
  yield* takeEvery(LOAD_CATALOG_LIST, loadCatalogList);
}

export function* watchLoadAllCatalogs() {
  yield* takeEvery(LOAD_ALL_CATALOGS, loadAllCatalogs);
}

function* loadCatalogList(action: LoadCatalogListAction) {
  const actionType = FETCH_CATALOG_LIST;
  const storeId = action.payload.storeId;
  const localeCode = yield* select(selectCurrentLocale);

  const constName = Object.keys({ FETCH_CATALOG_LIST })[0].toString();
  const headersObj = {
    "x-store-id": storeId || "my-store",
    "x-locale-code": localeCode || "default",
    "x-currency-code": "USD",
  };
  try {
    const endpoint = acquireEndpoint(constName);
    yield* call(callApi, actionType, getData, null, endpoint, headersObj);
  } catch (e: any) {
    console.error(e);
    yield* put({ type: FETCH_CATALOG_LIST.FAILURE, message: e.message });
  }
}

function* loadAllCatalogs(action: LoadAllCatalogsAction) {
  const actionType = FETCH_ALL_CATALOGS;
  const constName = Object.keys({ FETCH_ALL_CATALOGS })[0].toString();
  const storeId = action.payload.storeId;
  const localeCode = yield* select(selectCurrentLocale);

  const headersObj = {
    "x-store-id": storeId || "my-store",
    "x-locale-code": localeCode || "default",
    "x-currency-code": "USD",
  };
  try {
    const endpoint = acquireEndpoint(constName);
    yield* call(callApi, actionType, getData, null, endpoint, headersObj);
  } catch (e: any) {
    console.error(e);
    yield* put({ type: FETCH_ALL_CATALOGS.FAILURE, message: e.message });
  }
}
