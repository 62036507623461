import { call, put, select, takeEvery } from "typed-redux-saga/macro";
import {
  ProductSocialAnalyticsAction,
  LOAD_SOCIAL_ANALYTICS_SERVICE_URL,
  FETCH_PRODUCT_SOCIAL_ANALYTICS,
  FETCH_QUERY_PRODUCT_SOCIAL_ANALYTICS,
} from "./SocialAnalyticsTypes";
import { callApi } from "../../utils/SagaUtils";
import { postData } from "../../services/ApiService";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { selectCurrentStoreId } from "../store-list/StoreListSelectors";

function* fetchProductSocialAnalytics(action: ProductSocialAnalyticsAction) {
  try {
    yield* put({ type: FETCH_PRODUCT_SOCIAL_ANALYTICS.REQUEST });
    const productCodes = action.payload.productCodes;
    const isRefreshingAll = action.payload.isRefreshingAllProducts;
    const storeId = yield* select(selectCurrentStoreId);
    const constName = Object.keys({
      FETCH_PRODUCT_SOCIAL_ANALYTICS,
    })[0].toString();
    const actionType = FETCH_PRODUCT_SOCIAL_ANALYTICS;
    const serviceType = LOAD_SOCIAL_ANALYTICS_SERVICE_URL;
    const headersObj = {
      "x-store-id": storeId,
    };
    const endpoint = acquireEndpoint(
      constName,
      serviceType,
      isRefreshingAll ? "true" : "false",
    );
    if (productCodes.length > 0) {
      const productCount = productCodes.length;
      let numberOfAPIcallsInchunks = Math.ceil(productCount / 4);
      let currentIndex = 0;

      while (numberOfAPIcallsInchunks !== 0) {
        numberOfAPIcallsInchunks--;
        const productIdsChunk = productCodes.slice(
          currentIndex,
          currentIndex + 4,
        );
        const payload = {
          productCodes: productIdsChunk,
        };
        yield* call(
          callApi,
          actionType,
          postData,
          payload,
          endpoint,
          headersObj,
        );
        currentIndex = currentIndex + 4;
      }
    }
  } catch (e: any) {
    console.error(e);
    yield* put({ type: FETCH_PRODUCT_SOCIAL_ANALYTICS.FAILURE });
  }
}

export function* watchFetchProductSocialAnalytics() {
  yield* takeEvery(
    FETCH_QUERY_PRODUCT_SOCIAL_ANALYTICS,
    fetchProductSocialAnalytics,
  );
}
