export const styles = () => {
  return {
    bodyWrap: {
      display: "flex",
      flexDirection: "row !important",
      alignItems: "center",
    },
    select: {
      minWidth: 200,
      maxHeight: "32px",
      margin: "10px",
      border: "black",
    },
    skeleton: {
      display: "flex",
      flexDirection: "column",
      rowGap: "10px",
      padding: "10px",
    },
    muiListSubheader: {
      backgroundColor: "white",
      fontWeight: "bold",
      padding: "8px 16px",
      zIndex: 1,
    },
    muiMenuItem: {
      padding: "8px 16px",
    },
  };
};
