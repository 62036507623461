import React, { useEffect, useCallback, useState, useMemo } from "react";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentLocale } from "../../store/store-list/StoreListSelectors";
import { LoadChildNodes } from "../../store/add-product-categories/AddProductCategoriesActions";
import { selectTreeDataSelector } from "../../store/add-product-categories/AddProductCategoriesSelector";
import { useQuery } from "../../hooks/useQueryParams";
import {
  selectCategories,
  selectCurrentCategory,
} from "../../store/category/CategorySelectors";
import {
  decodeNodeId,
  encodeNodeId,
} from "../../store/add-product-categories/AddProductCategoriesUtils";
import {
  FormControlLabel,
  Skeleton,
  Box,
  Typography,
  styled,
} from "@mui/material";
import Tooltip from "../common/ToolTip";
import CheckboxComponent from "../common/Checkbox";

const classes = {
  rootLabel: "rootLabel",
  label: "label",
  selected: "selected",
  skeletonLabel: "skeletonLabel",
  root: "root",
};

const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${classes.skeletonLabel}`]: {
    marginTop: 10,
    display: "flex",
    justifyContent: "start",
  },
}));

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`&.${classes.rootLabel}`]: {
    "&:focus>.MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent !important",
    },
  },
  [`&.${classes.label}`]: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#78a6be",
    },
    fontSize: "18px",
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontFamily: "Helvetica",
  },
  [`&.${classes.selected}`]: {
    "&>.MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent !important",
      color: theme.palette.primary.main,
    },
  },
}));

const StyledTreeView = styled(TreeView)(({ theme }) => ({
  [`&.${classes.root}`]: {
    overflowX: "hidden",
    overflowY: "auto",
    flexGrow: 1,
    margin: theme.spacing(1),
  },
}));

interface Props {
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const ProductCategorySelection: React.FC<Props> = (props) => {
  const { selectedIds, setSelectedIds } = props;
  const query = useQuery();
  const [expanded, setExpanded] = useState<string[]>([]);
  const dispatch = useDispatch();
  const treeData = useSelector(selectTreeDataSelector);
  const currentStoreId = query.get("storeId") ?? "";
  const currentCatalogId = query.get("catalogId") ?? "";
  const currentCategoryId = useSelector(selectCurrentCategory);
  const categoryTree = useSelector(selectCategories);
  const [currentNodeId, setCurrentNodeId] = useState<string>("");
  const [setOfVisitedNodes, setSetOfVisitedNodes] = useState(new Set());

  function findCategoryById(data, targetId) {
    let currentNodeId = "";

    function backtrackParents(item, parents: string[] = []) {
      if (currentNodeId !== "") return;

      if (item.id === targetId) {
        currentNodeId = encodeNodeId([
          currentStoreId,
          currentCatalogId,
          ...parents,
          item.id,
        ]);
        return;
      }
      if (item.children && item.children.length > 0) {
        for (const child of item.children) {
          backtrackParents(child, [...parents, item.id]);
        }
      }
    }
    for (const item of data) {
      backtrackParents(item);
    }

    return currentNodeId;
  }

  const allParentNodeIds: string[] = useMemo(() => [], []);

  useEffect(() => {
    const parentPathParts = decodeNodeId(currentNodeId);

    for (let i = 0; i < parentPathParts.length - 1; i++) {
      if (i === 0) {
        allParentNodeIds.push(parentPathParts[i]);
      } else {
        allParentNodeIds.push(
          encodeNodeId([
            ...decodeNodeId(allParentNodeIds[i - 1]),
            parentPathParts[i],
          ]),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNodeId, currentCatalogId, currentStoreId]);

  useEffect(() => {
    dispatch(LoadChildNodes(null, currentLocaleId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentLocaleId = useSelector(selectCurrentLocale) ?? "";

  useEffect(() => {
    const currentNodeId = findCategoryById(categoryTree, currentCategoryId);
    if (currentNodeId !== "") {
      setSelectedIds([currentNodeId]);
      setCurrentNodeId(currentNodeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryTree, currentCategoryId]);

  useEffect(() => {
    setExpanded([]);
  }, [currentCatalogId]);

  const handleClick = useCallback(
    (categoryId: string) => {
      const copiedIds = selectedIds.slice();
      const index = copiedIds.findIndex((id) => id === categoryId);
      if (index >= 0) copiedIds.splice(index, 1);
      else copiedIds.push(categoryId);
      setSelectedIds(copiedIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIds],
  );

  const handleToggle = useCallback(
    (event: object, nodeIds: string[] | string) => {
      const value = nodeIds as string[];
      // load only when new node is selected and ignore if the node is revisted with expand or collapse
      if (nodeIds[0] && !setOfVisitedNodes.has(nodeIds[0])) {
        // we are setting nodeIds to know upto which child the tree is expanded
        // so when we collapse and again expand, we will not render tree from scratch
        // which we previously already loaded
        setSetOfVisitedNodes((prev: any) => {
          if (prev.has(nodeIds[0])) {
            return;
          } else {
            prev.add(nodeIds[0]);
          }
          return prev;
        });
        dispatch(LoadChildNodes(nodeIds[0], currentLocaleId));
        const keyLength = Object.keys(event).length;
        if (keyLength > 0) setExpanded(value);
      } else {
        const keyLength = Object.keys(event).length;
        if (keyLength > 0) setExpanded(value);
      }
    },
    [dispatch, currentLocaleId, setOfVisitedNodes],
  );

  const renderLabel = useCallback(
    (node: any) => (
      <Tooltip tooltipTitle={node.id} placement="right">
        <Box>
          {node.nodeId === currentNodeId ? (
            <Typography variant="subHeader">
              {node.name}(current category)
            </Typography>
          ) : (
            <Typography variant="sidebarTreeTitle">{node.name}</Typography>
          )}
        </Box>
      </Tooltip>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentNodeId],
  );

  const renderTree = useCallback(
    (nodes: any) => {
      return (
        <StyledTreeItem
          key={nodes.nodeId}
          nodeId={nodes.nodeId}
          label={
            decodeNodeId(nodes.nodeId).length > 2 ? (
              <FormControlLabel
                control={
                  <CheckboxComponent
                    checkboxId="ProductCategorySelection"
                    checked={selectedIds.includes(nodes.nodeId)}
                    handleChange={handleClick}
                    handleParam={nodes.nodeId}
                  />
                }
                label={renderLabel(nodes)}
              />
            ) : (
              renderLabel(nodes)
            )
          }
          classes={{
            root: classes.rootLabel,
            label: classes.label,
            selected: classes.selected,
          }}
        >
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => {
                if (
                  node.hasOwnProperty("childCategoryCount") &&
                  node.childCategoryCount === 0
                ) {
                  return (
                    <div style={{ marginLeft: 25 }}>
                      <FormControlLabel
                        control={
                          <CheckboxComponent
                            checkboxId="ProductCategorySelection"
                            checked={selectedIds.includes(node.nodeId)}
                            handleChange={handleClick}
                            handleParam={node.nodeId}
                          />
                        }
                        label={renderLabel(node)}
                      />
                    </div>
                  );
                } else return renderTree(node);
              })
            : !nodes.children &&
                Array.from(Array(nodes.childCategoryCount)).length > 0
              ? Array.from(Array(nodes.childCategoryCount)).map((_) => (
                  <StyledDiv className={classes.skeletonLabel}>
                    <Skeleton
                      variant="rectangular"
                      width={"40%"}
                      height={15}
                      animation="wave"
                    />
                  </StyledDiv>
                ))
              : null}
        </StyledTreeItem>
      );
    },
    [renderLabel, selectedIds, handleClick],
  );

  const checkNodeIds = useCallback(
    (targetArr) => {
      const idsMap = {};
      targetArr.forEach((c) => (idsMap[c] = true));
      const findData = allParentNodeIds.every((id) => idsMap[id]);
      return findData;
    },
    [allParentNodeIds],
  );

  const handleParentCategories = () => {
    const copiedIds = [...selectedIds];
    const remove = checkNodeIds(copiedIds);

    if (!remove) {
      [...new Set(allParentNodeIds)].forEach((id) => {
        if (!copiedIds[id]) copiedIds.push(id);
      });
      setSelectedIds(copiedIds);
    } else {
      const parentMap = {};
      allParentNodeIds.forEach((p) => (parentMap[p] = true));
      const finalData: string[] = [];
      copiedIds.forEach((c) => {
        if (!parentMap[c]) {
          finalData.push(c);
        }
      });
      setSelectedIds(finalData);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <StyledTreeView
        className={classes.root}
        defaultCollapseIcon={<RemoveIcon fontSize="small" />}
        defaultExpandIcon={<AddIcon fontSize="small" />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >
        {treeData.length ? (
          treeData.map((data) => {
            return renderTree(data);
          })
        ) : (
          <Skeleton
            variant="rectangular"
            width={"40%"}
            height={15}
            animation="wave"
          />
        )}
      </StyledTreeView>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <FormControlLabel
          control={
            <CheckboxComponent
              checkboxId="ProductCategorySelection"
              checked={selectedIds.includes(currentNodeId)}
              handleChange={handleClick}
              handleParam={currentNodeId}
            />
          }
          label={
            <Typography variant="sidebarTreeTitle">
              Add to current category
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <CheckboxComponent
              checkboxId="ProductParentCategorySelection"
              checked={checkNodeIds(selectedIds)}
              handleChange={handleParentCategories}
            />
          }
          label={
            <Typography variant="sidebarTreeTitle">
              Add to parent category(s)
            </Typography>
          }
        />
      </div>
    </div>
  );
};

export default ProductCategorySelection;
